.cashManageModal {
	background: rgba(155, 155, 155, 0.1);
	backdrop-filter: blur(25px);

	.modal-dialog {
		align-items: center;
		justify-content: center;

		.modal-content {
			width: fit-content;
			background: none;
			border: none;

			.modal-body {
				.lineWave {
					svg {
						margin-left: 30px;
						margin-bottom: 70px;

						rect {
							width: 7px;
							height: 20px;
						}
					}
				}

				form {
					display: flex;
					padding: 0;
					width: fit-content;
					align-items: start;
					justify-content: center;
					gap: 24px;

					.infoColumn {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						gap: 24px;

						.placeInfo {
							display: flex;
							width: 302px;
							padding: 24px;
							flex-direction: column;
							align-items: flex-start;
							gap: 8px;

							border-radius: 20px;
							border: 1px solid var(--light-gr-stroke, rgba(255, 255, 255, 0.8));
							background: var(
								--light-surface,
								linear-gradient(
									139deg,
									rgba(255, 255, 255, 0.8) 0.52%,
									rgba(249, 249, 249, 0.56) 49.32%,
									rgba(239, 239, 239, 0.24) 98.12%
								)
							);
							box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
							backdrop-filter: blur(40px);

							p,
							h1,
							h3 {
								margin: 0;
							}

							.title {
								display: flex;
								align-items: center;
								justify-content: space-between;
								width: 100%;

								h1 {
									color: #000;
									/* H1 */
									font-family: 'Red Hat Text';
									font-size: 24px;
									font-style: normal;
									font-weight: 700;
									line-height: normal;
								}

								.colorWrapper {
									display: flex;
									padding: 14px 18px;
									align-items: center;
									justify-content: center;
									gap: 10px;

									border-radius: 20px;
									border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
									background: var(
										--button-background-w,
										rgba(255, 255, 255, 0.6)
									);

									.color {
										width: 24px;
										height: 24px;
										border-radius: 8px;

										&.yellow {
											background: var(--Yellow, #f0ca00);
											box-shadow: 0px 0px 10px 0px rgba(255, 220, 78, 0.3);
										}

										&.blue {
											background: var(--Color, #3262de);
											box-shadow: 0px 0px 10px 0px rgba(50, 98, 222, 0.3);
										}

										&.orange {
											background: var(--Yellow, #f08000);
											box-shadow: 0px 0px 10px 0px rgba(255, 158, 78, 0.3);
										}

										&.purple {
											background: var(--Yellow, #6c03e6);
											box-shadow: 0px 0px 10px 0px rgba(184, 78, 255, 0.3);
										}

										&.green {
											background: var(--Green, #3bbb26);
											box-shadow: 0px 0px 10px 0px rgba(78, 255, 84, 0.3);
										}

										&.red {
											background: var(--Red, #ff4e4e);
											box-shadow: 0px 0px 10px 0px rgba(255, 78, 78, 0.3);
										}

										&.cyan {
											background: var(--Color, #32dec1);
											box-shadow: 0px 0px 10px 0px rgba(50, 211, 222, 0.3);
										}

										&.black {
											background: var(--Color, #000000);
											box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.384);
										}
									}
								}
							}

							.balancesBlocks {
								width: 100%;
								display: flex;
								flex-direction: column;
								align-items: start;
								justify-content: center;
								gap: 14px;

								.balance {
									display: flex;
									flex-direction: column;
									align-items: start;
									justify-content: center;

									p {
										color: var(--grey-w, #424242);
										font-family: Inter;
										font-size: 14px;
										font-style: normal;
										font-weight: 600;
										line-height: normal;
									}

									h1 {
										color: #000;
										font-family: Inter;
										font-size: 42px;
										font-style: normal;
										font-weight: 600;
										line-height: normal;
									}
								}

								.finalBonusCashOut {
									width: 100%;
									display: flex;
									align-items: center;
									justify-content: space-between;

									.finalBonus {
										display: flex;
										flex-direction: column;
										align-items: start;
										justify-content: center;
										gap: 4px;

										p {
											color: var(--grey-w, #424242);
											font-family: Inter;
											font-size: 14px;
											font-style: normal;
											font-weight: 600;
											line-height: normal;
										}

										h3 {
											color: #000;
											font-family: Inter;
											font-size: 24px;
											font-style: normal;
											font-weight: 600;
											line-height: normal;
										}
									}

									.cashOutBtn {
										width: 100%;
										height: 50px;
										padding: 9.5px 16px !important;
									}
								}
							}
						}

						.quickNumbers {
							display: flex;
							width: 302px;
							padding: 24px;
							flex-direction: column;
							align-items: flex-start;
							gap: 14px;
							flex: 1 0 0;

							border-radius: 20px;
							border: 1px solid var(--light-gr-stroke, rgba(255, 255, 255, 0.8));
							background: var(
								--light-surface,
								linear-gradient(
									139deg,
									rgba(255, 255, 255, 0.8) 0.52%,
									rgba(249, 249, 249, 0.56) 49.32%,
									rgba(239, 239, 239, 0.24) 98.12%
								)
							);
							box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.06);
							backdrop-filter: blur(40px);

							h3 {
								color: #000;
								font-family: Inter;
								font-size: 18px;
								font-style: normal;
								font-weight: 600;
								line-height: normal;
							}

							.btn-group {
								width: 100%;

								button {
									width: 50% !important;
									font-size: 14px !important;

									&:first-child {
										border-radius: 20px 0px 0px 20px !important;
									}

									&:last-child {
										border-radius: 0px 20px 20px 0px !important;
									}
								}
							}

							.btns {
								width: 100%;
								display: flex;
								flex-wrap: wrap;
								align-items: center;
								justify-content: space-between;
								gap: 8px;
								row-gap: 8px;

								.quickNumberBtn {
									width: calc(50% - 4px) !important;
									font-size: 16.201px !important;
									font-weight: 600 !important;
									padding: 18px 41px !important;

									&.deposit {
										color: var(--Green, #3bbb26) !important;
									}

									&.withdraw {
										color: var(--Red, #ff4e4e) !important;
									}
								}
							}
						}
					}

					.numpadWrapper {
						display: flex;
						padding: 24px;
						flex-direction: column;
						align-items: flex-start;
						gap: 14px;

						border-radius: 20px;
						border: 1px solid var(--light-gr-stroke, rgba(255, 255, 255, 0.8));
						background: var(
							--light-surface,
							linear-gradient(
								139deg,
								rgba(255, 255, 255, 0.8) 0.52%,
								rgba(249, 249, 249, 0.56) 49.32%,
								rgba(239, 239, 239, 0.24) 98.12%
							)
						);
						box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.06);
						backdrop-filter: blur(40px);

						.numpad {
							align-items: start;
							.input-wrapper {
								width: calc(100% - 64px);
								position: relative;
								.custom-form-control {
									position: relative;
									display: flex;
									height: 64px;
									padding: 0px 18px;
									justify-content: space-between;
									align-items: center;
									flex: 1 0 0;

									color: var(--Black, #000);
									text-align: end;
									font-family: Inter;
									font-size: 24px;
									font-style: normal;
									font-weight: 700;
									line-height: normal;
								}

								.extraInputSign {
									left: 18px;
									top: 19.5px;
								}

								img {
									width: 24px;
									height: 20.57px;
									top: 0;
									right: -66px;
									padding: 19.72px 18px;
									box-sizing: content-box;

									border-radius: 20px;
									border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
									background: var(
										--button-background-w,
										rgba(255, 255, 255, 0.6)
									);
									filter: none;
								}
							}

							.numbers {
								button:nth-last-child(2) {
									width: 190px !important;
								}
							}
						}

						.btns {
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: space-between;
							gap: 4px;

							.depositBtn,
							.withdrawBtn {
								width: calc(50% - 2px) !important;
								height: 86px !important;

								font-size: 22px !important;
							}
						}
					}

					.closeBtn {
						padding: 14px !important;

						img {
							width: 22.63px !important;
							height: 22.63px !important;
						}
					}

					.approvementBlock {
						display: inline-flex;
						padding: 24px;
						flex-direction: column;
						justify-content: center;
						align-items: flex-start;
						gap: 24px;

						border-radius: 20px;
						border: 1px solid
							var(--surfaces-gr-stroke, rgba(255, 255, 255, 0.8));
						background: var(
							--surfaces-lght-surface,
							linear-gradient(
								139deg,
								rgba(255, 255, 255, 0.8) 0.52%,
								rgba(249, 249, 249, 0.56) 49.32%,
								rgba(239, 239, 239, 0.24) 98.12%
							)
						);
						/* field blur+sh */
						box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.06);
						backdrop-filter: blur(50px);

						.texts {
							display: flex;
							flex-direction: column;
							align-items: start;
							justify-content: center;
							gap: 14px;

							h3 {
								color: #000;
								text-align: center;
								font-family: Inter;
								font-size: 18px;
								font-style: normal;
								font-weight: 600;
								line-height: normal;
							}

							.question {
								color: #000;
								text-align: center;
								font-family: Inter;
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: normal;

								span {
									font-weight: 600;
								}
							}

							.burn {
								color: #000;
								font-family: Inter;
								font-size: 14px;
								font-style: normal;
								font-weight: 600;
								line-height: normal;

								span {
									color: var(--Red, #ff4e4e);
								}
							}
						}

						.btns {
							display: flex;
							align-items: center;
							justify-content: space-between;
							width: 100%;

							button {
								width: calc(50% - 4px) !important;
							}
						}
					}
				}
			}
		}
	}

	&.mobileManage {
		.modal-dialog {
			margin: 0;
			width: 100%;
			height: 100%;

			.modal-content {
				width: 100%;
				height: 100%;

				.modal-header {
					display: flex;
					align-items: center;
					justify-content: space-between;
					border: none;
					padding: 14px 18px;

					.titleAndBackBtn {
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 8px;

						.backBtn {
							img {
								padding-right: 4px !important;
								width: 18px !important;
								height: 18px !important;
							}
						}

						h1 {
							margin: 0;
							color: #000;
							font-family: 'Red Hat Text';
							font-size: 24px;
							font-style: normal;
							font-weight: 700;
							line-height: normal;
						}
					}

					.menuBtn {
						border: none !important;
						background: none !important;
						box-shadow: none !important;
						padding: 0 !important;
						width: fit-content !important;
						height: fit-content !important;
						border-radius: 0% !important;

						img {
							width: 21px !important;
							height: 16px !important;
						}
					}
				}

				.modal-body {
					padding: 0;

					.lineWave {
						display: flex;
						width: 100%;
						height: 100%;
						justify-content: center;
						align-items: center;
					}

					.numpadWrapper {
						padding: 18px;
						height: 100%;
						width: 100vw;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: start;
						gap: 8px !important;

						border-radius: 20px;
						border: 1px solid var(--light-gr-stroke, rgba(255, 255, 255, 0.8));
						background: var(
							--Field-white-gr-mobile,
							linear-gradient(
								139deg,
								#fff 0.52%,
								rgba(255, 255, 255, 0.8) 49.32%,
								rgba(239, 239, 239, 0.7) 98.12%
							)
						);

						.placeInfo {
							width: 100%;
							display: flex;
							flex-direction: column;
							align-items: start;
							justify-content: center;
							gap: 8px;

							.title {
								width: 100%;
								display: flex;
								align-items: center;
								justify-content: space-between;

								.colorPlayer {
									display: flex;
									align-items: center;
									justify-content: center;
									gap: 8px;

									.color {
										width: 24px;
										height: 24px;
										border-radius: 8px;
										display: flex;
										align-items: center;
										justify-content: center;

										color: rgba(255, 255, 255, 0.8);
										font-family: Inter;
										font-size: 14px;
										font-style: normal;
										font-weight: 500;
										line-height: normal;

										&.yellow {
											background: var(--Yellow, #f0ca00);
											box-shadow: 0px 0px 10px 0px rgba(255, 220, 78, 0.3);
										}

										&.blue {
											background: var(--Color, #3262de);
											box-shadow: 0px 0px 10px 0px rgba(50, 98, 222, 0.3);
										}

										&.orange {
											background: var(--Yellow, #f08000);
											box-shadow: 0px 0px 10px 0px rgba(255, 158, 78, 0.3);
										}

										&.purple {
											background: var(--Yellow, #6c03e6);
											box-shadow: 0px 0px 10px 0px rgba(184, 78, 255, 0.3);
										}

										&.green {
											background: var(--Green, #3bbb26);
											box-shadow: 0px 0px 10px 0px rgba(78, 255, 84, 0.3);
										}

										&.red {
											background: var(--Red, #ff4e4e);
											box-shadow: 0px 0px 10px 0px rgba(255, 78, 78, 0.3);
										}

										&.cyan {
											background: var(--Color, #32dec1);
											box-shadow: 0px 0px 10px 0px rgba(50, 211, 222, 0.3);
										}

										&.black {
											background: var(--Color, #000000);
											box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.384);
										}
									}

									h1 {
										margin: 0;
										color: #000;
										/* H1 */
										font-family: 'Red Hat Text';
										font-size: 24px;
										font-style: normal;
										font-weight: 700;
										line-height: normal;
									}
								}

								.cashOutBtn {
									padding: 17.5px 24px !important;
								}
							}

							.balancesBlocks {
								display: flex;
								align-items: end;
								justify-content: space-between;
								width: 100%;

								.balance {
									display: flex;
									flex-direction: column;
									align-items: start;
									justify-content: center;
									gap: 0;

									p {
										margin: 0;
										color: var(--grey-w, #424242);
										font-family: Inter;
										font-size: 14px;
										font-style: normal;
										font-weight: 500;
										line-height: normal;
									}

									h1 {
										margin: 0;
										color: #000;
										font-family: Inter;
										font-size: 42px;
										font-style: normal;
										font-weight: 600;
										line-height: normal;
									}
								}

								.finalBonusCashOut {
									.finalBonus {
										display: flex;
										flex-direction: column;
										align-items: start;
										justify-content: center;
										gap: 0;

										p {
											margin: 0;
											color: var(--grey-w, #424242);
											font-family: Inter;
											font-size: 14px;
											font-style: normal;
											font-weight: 500;
											line-height: normal;
										}

										h3 {
											margin: 0;
											color: #000;
											font-family: Inter;
											font-size: 24px;
											font-style: normal;
											font-weight: 600;
											line-height: normal;
										}
									}
								}
							}
						}

						.numpad {
							width: 337px;
							.input-wrapper {
								width: calc(100% - 66px);

								.custom-form-control {
									height: 52px;
								}

								p {
									top: 15.5px;
								}

								img {
									padding: 14.72px 13px;
								}
							}

							.numbers {
								width: 100%;
								height: fit-content;

								.numBtn {
									width: 111px !important;
									height: 69px !important;
								}

								.numBtn:nth-last-child(2) {
									width: 224px !important;
								}
							}
						}

						.btns {
							button {
								padding: 8px 24px !important;
								gap: 4px !important;
								height: 70px !important;
								width: 163.5px !important;

								font-size: 18px !important;
								font-weight: 600 !important;

								img {
									width: 18.89px !important;
									height: 18.89px !important;
									padding: 7.5px;
									box-sizing: content-box;
								}
							}
						}
					}

					.approvementBlock {
						align-self: center;
					}
				}
			}
		}
	}
}

.desktop {
	.deviceHistoryTab {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 7.5px 0 14.5px 0;
		border-bottom: 1px solid rgba(0, 0, 0, 0.07); // #ebe6e4

		p {
			color: var(--grey-w, #424242);
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		.type {
			width: 73px;
			display: flex;
			align-items: center;
			justify-content: start;
			gap: 4px;

			img {
				width: 14px;
				height: 14px;
				padding: 2px;
				box-sizing: content-box;
			}

			&.deposit {
				img {
					filter: brightness(0) saturate(100%) invert(58%) sepia(66%)
						saturate(522%) hue-rotate(65deg) brightness(88%) contrast(103%);
				}

				p {
					color: #3bbb26;
				}
			}

			&.withdraw {
				img {
					filter: brightness(0) saturate(100%) invert(36%) sepia(100%)
						saturate(1214%) hue-rotate(332deg) brightness(111%) contrast(108%);
				}

				p {
					color: #ff5151;
				}
			}
		}

		.transactionId {
			width: 115px; // ???????
			cursor: pointer;
			text-decoration-line: underline;
			text-decoration-style: solid;
			text-decoration-skip-ink: none;
			text-decoration-thickness: auto;
			text-underline-offset: auto;
			text-underline-position: from-font;

			img {
				display: none;
				margin-left: 5px;
				width: 12px;
				height: 12px;
			}

			&:hover {
				img {
					transform: translateY(-0.75px) !important;
					display: inline;
					filter: brightness(0) saturate(100%) invert(0%) sepia(5%)
						saturate(7481%) hue-rotate(279deg) brightness(97%) contrast(106%);
				}
			}
		}

		.player {
			width: 81px;
			display: flex;
			align-items: center;
			justify-content: start;
			gap: 4px;

			.color {
				width: 8px;
				height: 8px;
				border-radius: 2.5px;

				&.yellow {
					background: var(--Yellow, #f0ca00);
					box-shadow: 0px 0px 10px 0px rgba(255, 220, 78, 0.3);
				}

				&.blue {
					background: var(--Color, #3262de);
					box-shadow: 0px 0px 10px 0px rgba(50, 98, 222, 0.3);
				}

				&.orange {
					background: var(--Yellow, #f08000);
					box-shadow: 0px 0px 10px 0px rgba(255, 158, 78, 0.3);
				}

				&.purple {
					background: var(--Yellow, #6c03e6);
					box-shadow: 0px 0px 10px 0px rgba(184, 78, 255, 0.3);
				}

				&.green {
					background: var(--Green, #3bbb26);
					box-shadow: 0px 0px 10px 0px rgba(78, 255, 84, 0.3);
				}

				&.red {
					background: var(--Red, #ff4e4e);
					box-shadow: 0px 0px 10px 0px rgba(255, 78, 78, 0.3);
				}

				&.cyan {
					background: var(--Color, #32dec1);
					box-shadow: 0px 0px 10px 0px rgba(50, 211, 222, 0.3);
				}

				&.black {
					background: var(--Color, #000000);
					box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.384);
				}
			}
		}

		.amount {
			width: 100px;

			&.deposit {
				color: #3bbb26;
			}

			&.withdraw {
				color: #ff5151;
			}
		}

		.date {
			// width: 110px;
			width: 79px;
		}
	}
}

.tablet {
	.deviceHistoryTab {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 7.5px 0 14.5px 0;
		border-bottom: 1px solid rgba(0, 0, 0, 0.07); // #ebe6e4

		p {
			color: var(--grey-w, #424242);
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		.type {
			width: 33px;
			// width: 73px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 4px;

			img {
				width: 18px;
				height: 18px;
				box-sizing: content-box;
			}

			&.deposit {
				img {
					filter: brightness(0) saturate(100%) invert(58%) sepia(66%)
						saturate(522%) hue-rotate(65deg) brightness(88%) contrast(103%);
				}

				p {
					color: #3bbb26;
				}
			}

			&.withdraw {
				img {
					filter: brightness(0) saturate(100%) invert(36%) sepia(100%)
						saturate(1214%) hue-rotate(332deg) brightness(111%) contrast(108%);
				}

				p {
					color: #ff5151;
				}
			}
		}

		.transactionId {
			width: 80px;
			// width: 62px;

			text-decoration-line: underline;
			text-decoration-style: solid;
			text-decoration-skip-ink: none;
			text-decoration-thickness: auto;
			text-underline-offset: auto;
			text-underline-position: from-font;

			img {
				margin-left: 5px;
				width: 15px;
				height: 15px;
			}
		}

		.player {
			// width: 81px;
			display: flex;
			align-items: center;
			justify-content: start;
			gap: 4px;

			.color {
				width: 24px;
				height: 24px;
				border-radius: 8px;
				display: flex;
				align-items: center;
				justify-content: center;

				color: rgba(255, 255, 255, 0.8);
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;

				&.yellow {
					background: var(--Yellow, #f0ca00);
					box-shadow: 0px 0px 10px 0px rgba(255, 220, 78, 0.3);
				}

				&.blue {
					background: var(--Color, #3262de);
					box-shadow: 0px 0px 10px 0px rgba(50, 98, 222, 0.3);
				}

				&.orange {
					background: var(--Yellow, #f08000);
					box-shadow: 0px 0px 10px 0px rgba(255, 158, 78, 0.3);
				}

				&.purple {
					background: var(--Yellow, #6c03e6);
					box-shadow: 0px 0px 10px 0px rgba(184, 78, 255, 0.3);
				}

				&.green {
					background: var(--Green, #3bbb26);
					box-shadow: 0px 0px 10px 0px rgba(78, 255, 84, 0.3);
				}

				&.red {
					background: var(--Red, #ff4e4e);
					box-shadow: 0px 0px 10px 0px rgba(255, 78, 78, 0.3);
				}

				&.cyan {
					background: var(--Color, #32dec1);
					box-shadow: 0px 0px 10px 0px rgba(50, 211, 222, 0.3);
				}

				&.black {
					background: var(--Color, #000000);
					box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.384);
				}
			}
		}

		.amount {
			// width: 104px;
			width: 70px;

			&.deposit {
				color: #3bbb26;
			}

			&.withdraw {
				color: #ff5151;
			}
		}

		.date {
			// width: 110px;
			// width: 79px;
			width: 73px;
		}
	}
}

.desktop,
.tablet,
.mobile {
	.navbarComponent {
		&.cashdesk {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 18px;

			.titleGamePlatform {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 18px;

				&.reverse {
					flex-direction: row-reverse;
					gap: 14px;

					.titleArrow {
						display: none;
					}
				}

				.timeTitle {
					display: flex;
					flex-direction: column;
					align-items: start;
					gap: 4px;
				}

				/* h1 {
					color: #000;
					font-family: 'Red Hat Text';
					font-size: 26px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				} */

				/* .gamePlatformDropdown {
					.dropdown-toggle {
						// padding: 9.5px 28.06px !important;
					}
				} */

				/* .titleArrow {
					filter: brightness(0) saturate(100%) invert(97%) sepia(8%)
						saturate(717%) hue-rotate(60deg) brightness(99%) contrast(90%);
				} */
			}

			.timeNavbarBtnsProfile {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 18px;

				.navbarBtns {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 8px;

					img {
						filter: brightness(0) saturate(100%) invert(100%) sepia(63%)
							saturate(41%) hue-rotate(219deg) brightness(115%) contrast(100%);
					}
				}

				.profile {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 8px;
					text-decoration: none;

					p {
						color: var(--grey-w, #424242);
						font-family: Inter;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						text-align: center;
					}

					p:nth-child(1) {
						height: 17px;
						img {
							display: none;
							margin-left: 5px;
							width: 14px;
							height: 14px;
							transform: translateY(-1.5px);
						}

						&:hover {
							img {
								display: inline;
							}
						}
					}

					.avatar {
						display: flex;
						width: 29px;
						height: 29px;
						justify-content: center;
						align-items: center;
						border-radius: 50%;
						flex-shrink: 0;
						background: linear-gradient(
								95deg,
								rgba(255, 255, 255, 0.2) 1.93%,
								rgba(255, 255, 255, 0.04) 97.62%
							),
							var(--Color, #3262de);

						color: var(--White, #fff);
						text-align: center;
						font-family: Inter;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}
				}
			}

			.dgaf {
				display: flex;
				align-items: center;
				gap: 12px;

				// TABLET
				.profile {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 8px;
					text-decoration: none;
					margin-right: 12px;
					text-align: center;

					p {
						color: var(--grey-w, #424242);
						font-family: Inter;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}

					p:nth-child(1) {
						height: 17px;
						img {
							margin-left: 5px;
							width: 14px;
							height: 14px;
							transform: translateY(-1.5px);
						}
					}

					.avatar {
						display: flex;
						width: 29px;
						height: 29px;
						justify-content: center;
						align-items: center;
						border-radius: 50%;
						flex-shrink: 0;
						background: linear-gradient(
								95deg,
								rgba(255, 255, 255, 0.2) 1.93%,
								rgba(255, 255, 255, 0.04) 97.62%
							),
							var(--Color, #3262de);

						color: var(--White, #fff);
						text-align: center;
						font-family: Inter;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}
				}

				//MOBILE
				p {
					color: var(--Black, #000);
					font-family: Inter;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					margin: 0;
				}

				p:nth-child(1) {
					height: 17px;
					img {
						margin-left: 5px;
						width: 14px;
						height: 14px;
						transform: translateY(-1.5px);
					}
				}

				.mobileMenuBtn {
					padding: 0;
					margin: 0;
					background: none;
					border: none;
					box-shadow: none;
				}
			}
		}
	}
}

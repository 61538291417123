.desktop {
	.historyTab {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 15px 0px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.07); // #ebe6e4

		p {
			color: var(--grey-w, #424242);
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;

			&.deviceName {
				width: 110px;
			}

			&.deviceId {
				width: 64px;
			}

			&.transactionId {
				width: 125px; // ???????
				cursor: pointer;
				text-decoration-line: underline;
				text-decoration-style: solid;
				text-decoration-skip-ink: none;
				text-decoration-thickness: auto;
				text-underline-offset: auto;
				text-underline-position: from-font;

				img {
					display: none;
					margin-left: 5px;
					width: 12px;
					height: 12px;
				}

				&:hover {
					img {
						transform: translateY(-0.75px) !important;
						display: inline;
						filter: brightness(0) saturate(100%) invert(0%) sepia(5%)
							saturate(7481%) hue-rotate(279deg) brightness(97%) contrast(106%);
					}
				}
			}

			&.amount {
				width: 100px;

				&.deposit {
					color: #3bbb26;
				}

				&.withdraw {
					color: #ff5151;
				}
			}

			&.date {
				// width: 110px;
				width: 81px;
			}
		}

		.player {
			width: 90px;
			display: flex;
			align-items: center;
			justify-content: start;
			gap: 4px;

			.color {
				width: 8px;
				height: 8px;
				border-radius: 2.5px;

				&.yellow {
					background: var(--Yellow, #f0ca00);
					box-shadow: 0px 0px 10px 0px rgba(255, 220, 78, 0.3);
				}

				&.blue {
					background: var(--Color, #3262de);
					box-shadow: 0px 0px 10px 0px rgba(50, 98, 222, 0.3);
				}

				&.orange {
					background: var(--Yellow, #f08000);
					box-shadow: 0px 0px 10px 0px rgba(255, 158, 78, 0.3);
				}

				&.purple {
					background: var(--Yellow, #6c03e6);
					box-shadow: 0px 0px 10px 0px rgba(184, 78, 255, 0.3);
				}

				&.green {
					background: var(--Green, #3bbb26);
					box-shadow: 0px 0px 10px 0px rgba(78, 255, 84, 0.3);
				}

				&.red {
					background: var(--Red, #ff4e4e);
					box-shadow: 0px 0px 10px 0px rgba(255, 78, 78, 0.3);
				}

				&.cyan {
					background: var(--Color, #32dec1);
					box-shadow: 0px 0px 10px 0px rgba(50, 211, 222, 0.3);
				}

				&.black {
					background: var(--Color, #000000);
					box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.384);
				}
			}
		}

		.type {
			width: 73px;
			display: flex;
			align-items: center;
			justify-content: start;
			gap: 4px;

			img {
				width: 14px;
				height: 14px;
				padding: 2px;
				box-sizing: content-box;
			}

			&.deposit {
				img {
					filter: brightness(0) saturate(100%) invert(58%) sepia(66%)
						saturate(522%) hue-rotate(65deg) brightness(88%) contrast(103%);
				}

				p {
					color: #3bbb26;
				}
			}

			&.withdraw {
				img {
					filter: brightness(0) saturate(100%) invert(36%) sepia(100%)
						saturate(1214%) hue-rotate(332deg) brightness(111%) contrast(108%);
				}

				p {
					color: #ff5151;
				}
			}
		}
	}
}

.tablet {
	.historyTab {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 15px 0px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.07); // #ebe6e4

		p {
			color: var(--grey-w, #424242);
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;

			&.deviceName {
				width: 110px;
			}

			&.deviceId {
				width: 64px;
			}

			&.transactionId {
				width: 115px;
				// width: 62px;

				text-decoration-line: underline;
				text-decoration-style: solid;
				text-decoration-skip-ink: none;
				text-decoration-thickness: auto;
				text-underline-offset: auto;
				text-underline-position: from-font;
				text-align: center;

				img {
					margin-left: 5px;
					width: 15px;
					height: 15px;
				}
			}

			&.amount {
				width: 100px;

				&.deposit {
					color: #3bbb26;
				}

				&.withdraw {
					color: #ff5151;
				}
			}

			&.date {
				// width: 110px;
				width: 79px;
			}
		}

		.player {
			// width: 81px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 4px;
			width: 50px;

			.color {
				width: 24px;
				height: 24px;
				border-radius: 8px;
				display: flex;
				align-items: center;
				justify-content: center;

				color: rgba(255, 255, 255, 0.8);
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;

				&.yellow {
					background: var(--Yellow, #f0ca00);
					box-shadow: 0px 0px 10px 0px rgba(255, 220, 78, 0.3);
				}

				&.blue {
					background: var(--Color, #3262de);
					box-shadow: 0px 0px 10px 0px rgba(50, 98, 222, 0.3);
				}

				&.orange {
					background: var(--Yellow, #f08000);
					box-shadow: 0px 0px 10px 0px rgba(255, 158, 78, 0.3);
				}

				&.purple {
					background: var(--Yellow, #6c03e6);
					box-shadow: 0px 0px 10px 0px rgba(184, 78, 255, 0.3);
				}

				&.green {
					background: var(--Green, #3bbb26);
					box-shadow: 0px 0px 10px 0px rgba(78, 255, 84, 0.3);
				}

				&.red {
					background: var(--Red, #ff4e4e);
					box-shadow: 0px 0px 10px 0px rgba(255, 78, 78, 0.3);
				}

				&.cyan {
					background: var(--Color, #32dec1);
					box-shadow: 0px 0px 10px 0px rgba(50, 211, 222, 0.3);
				}

				&.black {
					background: var(--Color, #000000);
					box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.384);
				}
			}
		}

		.type {
			width: 33px;
			// width: 73px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 4px;

			img {
				width: 18px;
				height: 18px;
				box-sizing: content-box;
			}

			&.deposit {
				img {
					filter: brightness(0) saturate(100%) invert(58%) sepia(66%)
						saturate(522%) hue-rotate(65deg) brightness(88%) contrast(103%);
				}

				p {
					color: #3bbb26;
				}
			}

			&.withdraw {
				img {
					filter: brightness(0) saturate(100%) invert(36%) sepia(100%)
						saturate(1214%) hue-rotate(332deg) brightness(111%) contrast(108%);
				}

				p {
					color: #ff5151;
				}
			}
		}
	}
}

.mobile {
	.historyTab {
		border: none;
		background: none;
		box-shadow: none;
		width: 100%;
		position: relative;

		.historyHeader {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding: 10px 0px;
			border: none;
			border-bottom: 1px solid rgba(0, 0, 0, 0.07);
			background: none;
			box-shadow: none; // #ebe6e4
			opacity: 1;
			height: fit-content;
			transition: all 1.5 ease;

			&.active {
				transition: all 1.5 ease;
				opacity: 0;
				height: 0;
				padding: 0;
				border: 0;
				pointer-events: none;

				.accordionIcon {
					transform: rotate(180deg);
					transition: transform 0.5s;
				}
			}

			/* .accordion-button {
				padding: 0;
				border: none;
				background: none;
				box-shadow: none; */

			/* &::after {
					background-size: 12px 12px;
					width: 12px;
					height: 12px;
				}
			} */

			p {
				color: var(--grey-w, #424242);
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;

				&.deviceName {
					width: 115px;
				}

				/* &.deviceId {
					width: 64px;
				}

				&.transactionId {
					width: 115px;
					// width: 62px;

					text-decoration-line: underline;
					text-decoration-style: solid;
					text-decoration-skip-ink: none;
					text-decoration-thickness: auto;
					text-underline-offset: auto;
					text-underline-position: from-font;
					text-align: center;

					img {
						margin-left: 5px;
						width: 15px;
						height: 15px;
					}
				} */

				&.amount {
					width: 90px;

					&.deposit {
						color: #3bbb26;
					}

					&.withdraw {
						color: #ff5151;
					}
				}

				&.date {
					// width: 110px;
					width: 90px;
				}
			}

			.player {
				// width: 81px;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 4px;
				width: 50px;

				.color {
					width: 20px;
					height: 20px;
					border-radius: 7px;
					display: flex;
					align-items: center;
					justify-content: center;

					color: rgba(255, 255, 255, 0.8);
					font-family: Inter;
					font-size: 12px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;

					&.yellow {
						background: var(--Yellow, #f0ca00);
						box-shadow: 0px 0px 10px 0px rgba(255, 220, 78, 0.3);
					}

					&.blue {
						background: var(--Color, #3262de);
						box-shadow: 0px 0px 10px 0px rgba(50, 98, 222, 0.3);
					}

					&.orange {
						background: var(--Yellow, #f08000);
						box-shadow: 0px 0px 10px 0px rgba(255, 158, 78, 0.3);
					}

					&.purple {
						background: var(--Yellow, #6c03e6);
						box-shadow: 0px 0px 10px 0px rgba(184, 78, 255, 0.3);
					}

					&.green {
						background: var(--Green, #3bbb26);
						box-shadow: 0px 0px 10px 0px rgba(78, 255, 84, 0.3);
					}

					&.red {
						background: var(--Red, #ff4e4e);
						box-shadow: 0px 0px 10px 0px rgba(255, 78, 78, 0.3);
					}

					&.cyan {
						background: var(--Color, #32dec1);
						box-shadow: 0px 0px 10px 0px rgba(50, 211, 222, 0.3);
					}

					&.black {
						background: var(--Color, #000000);
						box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.384);
					}
				}
			}

			.type {
				width: 42px;
				// width: 73px;
				display: flex;
				align-items: center;
				justify-content: center;
				// gap: 4px;

				img {
					width: 18px;
					height: 18px;
					box-sizing: content-box;
				}

				&.deposit {
					img {
						filter: brightness(0) saturate(100%) invert(58%) sepia(66%)
							saturate(522%) hue-rotate(65deg) brightness(88%) contrast(103%);
					}

					p {
						color: #3bbb26;
					}
				}

				&.withdraw {
					img {
						filter: brightness(0) saturate(100%) invert(36%) sepia(100%)
							saturate(1214%) hue-rotate(332deg) brightness(111%) contrast(108%);
					}

					p {
						color: #ff5151;
					}
				}
			}

			.accordionIcon {
				margin: 0 2px 0 2px;
				transition: transform 0.5s;

				@media print {
					display: none; // for react-to-print
				}
			}
		}

		.historyBody {
			padding: 0;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: start;
			gap: 4px;

			.info {
				display: flex;
				flex-direction: column;
				width: 100%;
				align-items: start;
				justify-content: start;
				gap: 14px;
				padding: 12px 18px;

				border-radius: 0px 20px 20px 0px;
				border-top: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
				border-right: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
				border-bottom: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
				background: var(--button-background-w, rgba(255, 255, 255, 0.6));
				/* Block shadow */
				box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);

				.string {
					display: flex;
					flex-direction: column;
					align-items: start;
					justify-content: center;
					gap: 2px;

					p {
						font-family: Inter;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}

					.title {
						color: var(--grey-w, #424242);
						font-size: 12px;
					}

					.value {
						color: var(--Black, #000);
						font-size: 14px;
					}
				}

				.extraInfo {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.column {
						display: flex;
						flex-direction: column;
						align-items: start;
						justify-content: center;
						gap: 14px;

						.player > .value {
							// width: 81px;
							display: flex;
							align-items: center;
							justify-content: center;
							gap: 4px;
							width: 100%;

							.color {
								width: 20px;
								height: 20px;
								border-radius: 7px;
								display: flex;
								align-items: center;
								justify-content: center;

								color: rgba(255, 255, 255, 0.8);
								font-family: Inter;
								font-size: 12px;
								font-style: normal;
								font-weight: 500;
								line-height: normal;

								&.yellow {
									background: var(--Yellow, #f0ca00);
									box-shadow: 0px 0px 10px 0px rgba(255, 220, 78, 0.3);
								}

								&.blue {
									background: var(--Color, #3262de);
									box-shadow: 0px 0px 10px 0px rgba(50, 98, 222, 0.3);
								}

								&.orange {
									background: var(--Yellow, #f08000);
									box-shadow: 0px 0px 10px 0px rgba(255, 158, 78, 0.3);
								}

								&.purple {
									background: var(--Yellow, #6c03e6);
									box-shadow: 0px 0px 10px 0px rgba(184, 78, 255, 0.3);
								}

								&.green {
									background: var(--Green, #3bbb26);
									box-shadow: 0px 0px 10px 0px rgba(78, 255, 84, 0.3);
								}

								&.red {
									background: var(--Red, #ff4e4e);
									box-shadow: 0px 0px 10px 0px rgba(255, 78, 78, 0.3);
								}

								&.cyan {
									background: var(--Color, #32dec1);
									box-shadow: 0px 0px 10px 0px rgba(50, 211, 222, 0.3);
								}

								&.black {
									background: var(--Color, #000000);
									box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.384);
								}
							}
						}

						.transactionId > .value {
							height: 17px;
							text-decoration-line: underline;
							text-decoration-style: solid;
							text-decoration-skip-ink: none;
							text-decoration-thickness: auto;
							text-underline-offset: auto;
							text-underline-position: from-font;

							img {
								margin-left: 5px;
								width: 14px;
								height: 14px;
								transform: translateY(-1.5px);
							}
						}

						.amount > .value {
							&.deposit {
								color: #3bbb26;
							}

							&.withdraw {
								color: #ff5151;
							}
						}

						.type > .value {
							display: flex;
							align-items: center;
							justify-content: center;
							gap: 4px;

							img {
								width: 14px;
								height: 14px;
								box-sizing: content-box;
							}

							&.deposit {
								img {
									filter: brightness(0) saturate(100%) invert(58%) sepia(66%)
										saturate(522%) hue-rotate(65deg) brightness(88%)
										contrast(103%);
								}

								p {
									color: #3bbb26;
								}
							}

							&.withdraw {
								img {
									filter: brightness(0) saturate(100%) invert(36%) sepia(100%)
										saturate(1214%) hue-rotate(332deg) brightness(111%)
										contrast(108%);
								}

								p {
									color: #ff5151;
								}
							}
						}
					}
				}
			}

			.closeBtn {
				border: none;
				background: none;
				box-shadow: none;

				img {
					width: 24px;
					height: 24px;
				}
			}
		}
	}
}

@font-face {
	font-family: 'Red Hat Text';
	font-weight: 100 900;
	src: url('../public/fonts/RedHatText-VariableFont_wght.ttf'), format('woff2');
}

@font-face {
	font-family: 'Inter';
	font-weight: 100 900;
	src: url('../public/fonts/Inter-VariableFont_opsz\,wght.ttf'), format('woff2');
}

html,
body {
	font-family: 'Inter', sans-serif;
	font-size: 14px;
	font-weight: normal;
	color: #000;
	margin: 0;
}

// TOASTIFY:
.Toastify {
	.Toastify__toast {
		border-radius: 5px;
		border-left: 8px solid;

		@media (max-width: 767px) {
			width: 80%;
			justify-self: end;
			align-self: end;
		}

		.Toastify__close-button {
			svg {
				width: 24px;
				height: 24px;
			}
		}

		.Toastify__toast-body {
			margin: 0;
			padding: 4px 48px;

			.Toastify__toast-icon {
				display: none;
			}

			div {
				&:last-child {
					text-align: center;
					font-feature-settings: 'liga' off, 'clig' off;
					font-family: Inter;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px;
				}
			}
		}

		&.Toastify__toast--error {
			border-left-color: #f39f9f;
			background: #fae1e1;

			.Toastify__toast-body {
				div {
					&:last-child {
						color: #d94040;
					}
				}
			}

			.Toastify__progress-bar--wrp {
				.Toastify__progress-bar--bg {
					background-color: transparent;
				}

				.Toastify__progress-bar {
					background-color: #f39f9f;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 8px;
					border-top-right-radius: 8px;
				}
			}
		}

		&.Toastify__toast--success {
			border-left-color: #00ffd0;
			background: #e2f9f2;

			.Toastify__toast-body {
				div {
					&:last-child {
						color: #13dfba;
					}
				}
			}

			.Toastify__progress-bar--wrp {
				.Toastify__progress-bar--bg {
					background-color: transparent;
				}

				.Toastify__progress-bar {
					background-color: #00ffd0;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 8px;
					border-top-right-radius: 8px;
				}
			}
		}

		&.Toastify__toast--warning {
			border-left-color: #ffc278;
			background: #faf3e1;

			.Toastify__toast-body {
				div {
					&:last-child {
						color: #fe9a22;
					}
				}
			}

			.Toastify__progress-bar--wrp {
				.Toastify__progress-bar--bg {
					background-color: transparent;
				}

				.Toastify__progress-bar {
					background-color: #ffc278;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 8px;
					border-top-right-radius: 8px;
				}
			}
		}
	}
}

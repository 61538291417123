.cashierBG {
	background: url('../../assets/authorizationBG.svg') no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1;
}

.desktop {
	.devices {
		display: flex;
		align-items: start;
		justify-content: space-between;
		gap: 24px;

		.devicesList {
			display: flex;
			width: 457px;
			height: calc(100vh - 77px);
			height: calc(100svh - 77px);
			padding: 24px 24px 0 24px;
			flex-direction: column;
			align-items: flex-start;
			gap: 24px;
			flex-shrink: 0;

			border-radius: 20px;
			border: 1px solid var(--light-gr-stroke, rgba(255, 255, 255, 0.8));
			background: var(
				--light-surface,
				linear-gradient(
					139deg,
					rgba(255, 255, 255, 0.8) 0.52%,
					rgba(249, 249, 249, 0.56) 49.32%,
					rgba(239, 239, 239, 0.24) 98.12%
				)
			);
			box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
			backdrop-filter: blur(15px);

			.typeToggleAddDevice {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;

				/* h2 {
					color: var(--Black, #000);
					font-family: 'Red Hat Text';
					font-size: 24px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				} */

				.btn-group {
					button {
						// height: 32px !important;
						padding: 9px 14px !important;

						&:first-child {
							border-radius: 20px 0px 0px 20px !important;
						}

						&:last-child {
							border-radius: 0px 20px 20px 0px !important;
						}
					}
				}

				.addDeviceBtn {
					padding: 13.5px 18px !important;
				}
			}

			.devicesTable {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: start;
				justify-content: start;
				gap: 14px;
				// height: calc(100% - 48px);
				overflow: hidden;

				.titles {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0 18px 0 12px;
					width: 100%;

					p {
						color: var(--grey-w, #424242);
						font-family: Inter;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						display: block !important;
					}

					p:nth-child(1) {
						width: 10px;
					}

					p:nth-child(2) {
						// width: 84px;
						width: 65px;
						text-align: center;
					}

					p:nth-child(3) {
						width: 58px;
					}

					p:nth-child(4) {
						// width: 50px;
						width: 35px;
						color: transparent;
						opacity: 0;
					}

					p:nth-child(5) {
						width: 50px;
						color: transparent;
						opacity: 0;
					}
				}

				.devicesBtns {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: start;
					justify-content: start;
					gap: 8px;
					overflow-y: auto;
					// scroll-behavior: smooth;
					// scrollbar-width: thin;
				}
			}
		}

		.deviceDetails {
			display: flex;
			width: 673px;
			height: calc(100vh - 77px);
			height: calc(100svh - 77px);
			padding: 24px 24px 0 24px;
			flex-direction: column;
			align-items: flex-start;
			gap: 24px;
			flex-shrink: 0;

			border-radius: 20px;
			border: 1px solid var(--light-gr-stroke, rgba(255, 255, 255, 0.8));
			background: var(
				--light-surface,
				linear-gradient(
					139deg,
					rgba(255, 255, 255, 0.8) 0.52%,
					rgba(249, 249, 249, 0.56) 49.32%,
					rgba(239, 239, 239, 0.24) 98.12%
				)
			);
			box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
			backdrop-filter: blur(15px);

			.detailsHeader {
				display: flex;
				align-items: center;
				justify-content: /* center */ space-between;
				// gap: 14px;
				width: 100%;
			}

			.titleAndToggler {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;

				h1 {
					color: var(--Black, #000);
					font-family: 'Red Hat Text';
					font-size: 24px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				}

				.btn-group {
					button {
						// height: 32px !important;
						padding: 9px 14px !important;

						&:first-child {
							border-radius: 20px 0px 0px 20px !important;
						}

						&:last-child {
							border-radius: 0px 20px 20px 0px !important;
						}
					}
				}
			}

			.detailsTable {
				display: flex;
				flex-direction: column;
				align-items: start;
				justify-content: start;
				gap: 14px;
				width: 100%;
				height: 100%;
				overflow: hidden;

				.titles {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;

					&.players {
						padding: 0 18px;
					}

					p {
						color: var(--grey-w, #424242);
						font-family: Inter;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;

						&.username {
							width: 90px;
						}

						&.color {
							width: 52px;
						}

						&.balance {
							width: 67px;
						}

						&.type {
							width: 73px;
						}

						&.transactionId {
							width: 125px;
						}

						&.player {
							width: 81px;
						}

						&.amount {
							width: 54px;
						}

						&.date {
							width: 79px;
						}
					}

					p:nth-child(4) {
						width: 116px;
					}
				}

				.detailsTabs {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: stretch;
					justify-content: start;
					gap: 8px;
					overflow-y: auto;
					// scroll-behavior: smooth;
					// scrollbar-width: thin;

					&.noPlaces {
						padding-top: 27px;

						.lineWave {
							align-self: center;

							svg {
								margin-left: 5px;

								rect {
									width: 7px;
									height: 20px;
								}
							}
						}

						div {
							display: flex;
							align-items: center;
							justify-content: center;
							position: relative;

							img {
								position: absolute;
								top: 14.5px;
								left: 20px;
								transform: rotate(180deg);
								height: 10px;
							}
						}

						p {
							color: #000;
							text-align: center;
							font-family: Inter;
							font-size: 28px;
							font-style: normal;
							font-weight: 800;
							line-height: normal;
							text-wrap: balance;
							white-space: pre-wrap;
						}
					}
				}
			}
		}
	}
}

.tablet {
	.devices {
		display: flex;
		align-items: start;
		justify-content: space-between;
		gap: 12px;

		.devicesList {
			display: flex;
			min-width: 329px;
			height: calc(100vh - 81px);
			height: calc(100svh - 81px);
			padding: 24px 12px 0 12px;
			flex-direction: column;
			align-items: flex-start;
			gap: 24px;

			border-radius: 20px;
			border: 1px solid var(--light-gr-stroke, rgba(255, 255, 255, 0.8));
			background: var(
				--light-surface,
				linear-gradient(
					139deg,
					rgba(255, 255, 255, 0.8) 0.52%,
					rgba(249, 249, 249, 0.56) 49.32%,
					rgba(239, 239, 239, 0.24) 98.12%
				)
			);
			box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
			backdrop-filter: blur(15px);

			.typeToggleAddDevice {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;

				/* h2 {
					color: var(--Black, #000);
					font-family: 'Red Hat Text';
					font-size: 24px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				} */

				.btn-group {
					button {
						// height: 32px !important;
						padding: 9px /* 14px */ 10px !important;

						&:first-child {
							border-radius: 20px 0px 0px 20px !important;
						}

						&:last-child {
							border-radius: 0px 20px 20px 0px !important;
						}
					}
				}

				.addDeviceBtn {
					padding: 9.5px /* 14px */ 10px !important;
				}
			}

			.devicesTable {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: start;
				justify-content: start;
				gap: 14px;
				// height: calc(100% - 48px);
				overflow: hidden;

				.titles {
					display: flex;
					padding: 0px 29px 0px 12px;
					justify-content: space-between;
					align-items: flex-start;
					align-self: stretch;
					width: 100%;

					p {
						color: var(--grey-w, #424242);
						font-family: Inter;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}

					p:nth-child(1) {
						width: 40px;
					}

					p:nth-child(2) {
						// width: 90px;
						width: 35.6px;
					}

					p:nth-child(3) {
						// width: 50px;
						// padding-left: 10px;
						width: 128px;
					}

					p:nth-child(4) {
						display: none;
					}

					p:nth-child(5) {
						display: none;
					}

					/* p:nth-child(3) {
						width: 50px;
						color: transparent;
						opacity: 0;
					}

					p:nth-child(4) {
						width: 50px;
						color: transparent;
						opacity: 0;
					} */
				}

				.devicesBtns {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: start;
					justify-content: start;
					gap: 8px;
					overflow-y: auto;
					// scroll-behavior: smooth;
					// scrollbar-width: thin;
				}
			}
		}

		.deviceDetails {
			display: flex;
			height: calc(100vh - 81px);
			height: calc(100svh - 81px);
			padding: 24px 12px 0 12px;
			flex-direction: column;
			align-items: flex-start;
			gap: 24px;
			flex: 1 0 0;

			border-radius: 20px;
			border: 1px solid var(--light-gr-stroke, rgba(255, 255, 255, 0.8));
			background: var(
				--light-surface,
				linear-gradient(
					139deg,
					rgba(255, 255, 255, 0.8) 0.52%,
					rgba(249, 249, 249, 0.56) 49.32%,
					rgba(239, 239, 239, 0.24) 98.12%
				)
			);
			box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
			backdrop-filter: blur(15px);

			.detailsHeader {
				display: flex;
				align-items: center;
				justify-content: /* center */ space-between;
				// gap: 8px;
				width: 100%;
			}

			.detailsTable {
				display: flex;
				flex-direction: column;
				align-items: start;
				justify-content: start;
				gap: 14px;
				width: 100%;
				height: 100%;
				overflow: hidden;

				.titleAndToggler {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;

					h1 {
						color: var(--Black, #000);
						font-family: 'Red Hat Text';
						font-size: 24px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
					}

					.btn-group {
						button {
							// height: 32px !important;
							padding: 9px 14px !important;

							&:first-child {
								border-radius: 20px 0px 0px 20px !important;
							}

							&:last-child {
								border-radius: 0px 20px 20px 0px !important;
							}
						}
					}
				}

				.titles {
					display: flex;
					justify-content: space-between;
					align-items: center;
					align-self: stretch;
					width: 100%;
					gap: 12px;

					&.players {
						padding: 0 18px;
					}

					p {
						color: var(--grey-w, #424242);
						font-family: Inter;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;

						&.username {
							width: 80px;
						}

						&.color {
							width: 31px;
							// text-align: center;
						}

						&.balance {
							width: 104px;
							// text-align: center;
						}

						&.mock {
							// width: 50px;
							display: none;
						}

						&.type {
							width: 33px;
						}

						&.transactionId {
							width: 64px;
							text-align: center;
						}

						&.player {
							width: 48px;
							text-align: end;
						}

						&.amount {
							width: 82px;
						}

						&.date {
							width: 73px;
						}
					}
				}

				.detailsTabs {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: stretch;
					justify-content: start;
					gap: 8px;
					overflow-y: auto;
					// scroll-behavior: smooth;
					// scrollbar-width: thin;

					&.noPlaces {
						padding-top: 27px;

						.lineWave {
							align-self: center;
						}

						p {
							color: #000;
							text-align: center;
							font-family: Inter;
							font-size: 28px;
							font-style: normal;
							font-weight: 800;
							line-height: normal;
							text-wrap: balance;
							white-space: pre-wrap;
						}
					}
				}
			}
		}
	}
}

.mobile {
	.devices {
		display: flex;
		align-items: start;
		justify-content: space-between;
		gap: 24px;

		.devicesList {
			display: flex;
			width: 100%;
			height: calc(100vh - 81px);
			height: calc(100svh - 81px);
			padding: 24px 18px 0 18px;
			flex-direction: column;
			align-items: flex-start;
			gap: 24px;
			flex-shrink: 0;

			border-radius: 20px;
			border: 1px solid var(--light-gr-stroke, rgba(255, 255, 255, 0.8));
			background: var(
				--light-surface,
				linear-gradient(
					139deg,
					rgba(255, 255, 255, 0.8) 0.52%,
					rgba(249, 249, 249, 0.56) 49.32%,
					rgba(239, 239, 239, 0.24) 98.12%
				)
			);
			box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
			backdrop-filter: blur(15px);

			.typeToggleAddDevice {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;

				/* h2 {
					color: var(--Black, #000);
					font-family: 'Red Hat Text';
					font-size: 24px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				} */

				.btn-group {
					button {
						// height: 32px !important;
						padding: 17.5px /* 18px */ 14px !important;

						&:first-child {
							border-radius: 20px 0px 0px 20px !important;
						}

						&:last-child {
							border-radius: 0px 20px 20px 0px !important;
						}
					}
				}

				.addDeviceBtn {
					padding: 17.5px /* 24px */ 14px !important;
				}
			}

			.devicesTable {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: start;
				justify-content: start;
				gap: 14px;
				// height: calc(100% - 48px);
				overflow: hidden;

				.titles {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0 18px 0 12px;
					width: 100%;

					p {
						color: var(--grey-w, #424242);
						font-family: Inter;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						display: block !important;
					}

					p:nth-child(1) {
						width: 20px;
					}

					p:nth-child(2) {
						// width: 90px;
						width: 100px;
						text-align: center;
					}

					/* p:nth-child(2) {
						width: 58px;
						padding-left: 5px;
					} */

					p:nth-child(4) {
						width: 50px;
						color: transparent;
						opacity: 0;
					}

					p:nth-child(5) {
						width: 50px;
						color: transparent;
						opacity: 0;
					}
				}

				.devicesBtns {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: start;
					justify-content: start;
					gap: 8px;
					overflow-y: auto;
					// scroll-behavior: smooth;
					// scrollbar-width: thin;
				}
			}
		}
	}
}

.lockModal {
	background: rgba(155, 155, 155, 0.1);
	backdrop-filter: blur(25px);

	.modal-dialog {
		align-items: center;
		justify-content: center;
		min-width: 100%;
		height: 100%;
		margin: 0;

		.modal-content {
			width: fit-content;
			background: none;
			border: none;

			.modal-body {
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;

				.lockBlock {
					display: flex;
					align-items: center;
					justify-content: center;

					.textsBlock {
						display: flex;
						flex-direction: column;
						align-items: start;
						justify-content: center;
						gap: 24px;
						width: 349px;

						h1 {
							color: var(--Black, #000);
							text-align: center;
							font-family: Inter;
							font-size: 32px;
							font-style: normal;
							font-weight: 800;
							line-height: normal;
							margin: 0;
						}

						.descriptionBlock {
							display: flex;
							flex-direction: column;
							align-items: start;
							justify-content: center;
							gap: 14px;

							p {
								margin: 0;

								&:first-child {
									color: var(--Black, #000);
									font-family: Inter;
									font-size: 18px;
									font-style: normal;
									font-weight: 500;
									line-height: normal;
								}

								&:last-child {
									color: var(--grey-w, #424242);
									font-family: Inter;
									font-size: 12px;
									font-style: normal;
									font-weight: 500;
									line-height: normal;
								}
							}
						}

						.logOutBtn {
							width: 100% !important;
							padding: 17.5px 24px !important;
						}
					}

					.lockIcon {
						filter: brightness(0) saturate(100%) invert(25%) sepia(0%)
							saturate(0%) hue-rotate(358deg) brightness(96%) contrast(92%);
					}
				}

				.lockIcon {
					width: 184px;
					height: 184px;
				}

				&.desktop {
					gap: 68px;

					.lockBlock {
						gap: 51px;
					}
				}

				&.tablet {
					gap: 30px;

					.lockBlock {
						flex-direction: column-reverse;
						gap: 24px;

						.textsBlock {
							align-items: center;

							.descriptionBlock {
								align-items: center;

								p {
									text-align: center;
									margin: 0;
								}
							}
						}
					}
				}

				&.mobile {
					display: flex;
					flex-direction: column-reverse;
					align-items: center;
					justify-content: center;
					// gap: 16px;

					.lockBlock {
						flex-direction: row-reverse;
						width: 100%;
						// margin-top: 16px;
						padding: 16px 8px 16px 0;
						gap: 8px;

						.textsBlock {
							gap: 12px;
							width: 100%;
							align-items: center;
						}

						.lockIcon {
							width: 64px;
							height: 64px;
						}
					}
				}

				.numpad {
					padding: 42px;
					border-radius: 20px 20px 0px 0px;
					border: 1px solid var(--light-gr-stroke, rgba(255, 255, 255, 0.8));
					background: var(
						--light-surface,
						linear-gradient(
							139deg,
							rgba(255, 255, 255, 0.8) 0.52%,
							rgba(249, 249, 249, 0.56) 49.32%,
							rgba(239, 239, 239, 0.24) 98.12%
						)
					);
					box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
					backdrop-filter: blur(40px);

					.numbers {
						.activateBtn {
							width: 190px !important;
							height: 94px !important;

							font-size: 18px !important;
							font-weight: 600 !important;
						}
					}
				}
			}
		}
	}
}

.filterAndSortDropdown {
	padding: 4px 18px 18px 18px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;

	.sorterWrapper,
	.filterWrapper {
		width: 100%;

		label {
			text-align: center !important;
			font-family: Inter !important;
			font-size: 14px !important;
			font-style: normal !important;
			font-weight: 500 !important;
			line-height: normal !important;
			color: var(--grey-w, #606060) !important;
			padding-left: 3px;
		}

		.dropdown-toggle {
			width: 100%;
			height: 42px;
			padding: 16px 18px;
			border-radius: 20px;
		}

		.dropdown-menu.show {
			max-height: 196px !important;
			overflow-y: auto;
			// scrollbar-width: thin;
			width: 100%;
			box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.17);

			.dropdown-item {
				justify-content: center;
			}
		}
	}
}

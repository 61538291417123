.desktop {
	.deviceDetailTab {
		display: flex;
		// height: 62px;
		padding: 9px 18px;
		justify-content: space-between;
		align-items: center;
		gap: 2px;
		align-self: stretch;

		border-radius: 30px;
		border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
		background: rgba(255, 255, 255, 0.7);

		p {
			color: var(--Black, #000);
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		p:nth-child(1) {
			width: 90px;
		}

		p:nth-child(3) {
			width: 57px;
		}

		.colorWrapper {
			width: 52px;

			.color {
				width: 24px;
				height: 24px;
				flex-shrink: 0;
				border-radius: 8px;

				&.yellow {
					background: var(--Yellow, #f0ca00);
					box-shadow: 0px 0px 10px 0px rgba(255, 220, 78, 0.3);
				}

				&.blue {
					background: var(--Color, #3262de);
					box-shadow: 0px 0px 10px 0px rgba(50, 98, 222, 0.3);
				}

				&.orange {
					background: var(--Yellow, #f08000);
					box-shadow: 0px 0px 10px 0px rgba(255, 158, 78, 0.3);
				}

				&.purple {
					background: var(--Yellow, #6c03e6);
					box-shadow: 0px 0px 10px 0px rgba(184, 78, 255, 0.3);
				}

				&.green {
					background: var(--Green, #3bbb26);
					box-shadow: 0px 0px 10px 0px rgba(78, 255, 84, 0.3);
				}

				&.red {
					background: var(--Red, #ff4e4e);
					box-shadow: 0px 0px 10px 0px rgba(255, 78, 78, 0.3);
				}

				&.cyan {
					background: var(--Color, #32dec1);
					box-shadow: 0px 0px 10px 0px rgba(50, 211, 222, 0.3);
				}

				&.black {
					background: var(--Color, #000000);
					box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.384);
				}
			}
		}

		.btnWrapper {
			display: flex;
			align-items: center;
			justify-content: end;
			width: 116px;

			.manageBtn {
				padding: 15.5px 18px !important;
			}
		}
	}
}

.tablet {
	.deviceDetailTab {
		display: flex;
		padding: 15px 18px;
		justify-content: space-between;
		align-items: center;
		flex-shrink: 0;
		align-self: stretch;

		border-radius: 30px;
		border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
		background: rgba(255, 255, 255, 0.7);

		p {
			color: var(--Black, #000);
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		p:nth-child(1) {
			width: 80px;
		}

		p:nth-child(3) {
			width: 104px;
		}

		.colorWrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 31px;

			.color {
				width: 24px;
				height: 24px;
				flex-shrink: 0;
				border-radius: 8px;

				&.yellow {
					background: var(--Yellow, #f0ca00);
					box-shadow: 0px 0px 10px 0px rgba(255, 220, 78, 0.3);
				}

				&.blue {
					background: var(--Color, #3262de);
					box-shadow: 0px 0px 10px 0px rgba(50, 98, 222, 0.3);
				}

				&.orange {
					background: var(--Yellow, #f08000);
					box-shadow: 0px 0px 10px 0px rgba(255, 158, 78, 0.3);
				}

				&.purple {
					background: var(--Yellow, #6c03e6);
					box-shadow: 0px 0px 10px 0px rgba(184, 78, 255, 0.3);
				}

				&.green {
					background: var(--Green, #3bbb26);
					box-shadow: 0px 0px 10px 0px rgba(78, 255, 84, 0.3);
				}

				&.red {
					background: var(--Red, #ff4e4e);
					box-shadow: 0px 0px 10px 0px rgba(255, 78, 78, 0.3);
				}

				&.cyan {
					background: var(--Color, #32dec1);
					box-shadow: 0px 0px 10px 0px rgba(50, 211, 222, 0.3);
				}

				&.black {
					background: var(--Color, #000000);
					box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.384);
				}
			}
		}

		.btnWrapper {
			display: flex;
			align-items: center;
			justify-content: end;

			.manageBtn {
				font-size: 0 !important;
				justify-content: center !important;
				gap: 0 !important;
			}
		}
	}
}

.cashierBG {
	background: url('../../assets/authorizationBG.svg') no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1;
}

.desktop {
	.historyPage {
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: start;
		gap: 24px;
		height: calc(100vh - 77px);
		height: calc(100svh - 77px);
		padding: 24px 42px 0 42px;

		border-radius: 20px;
		border: 1px solid var(--light-gr-stroke, rgba(255, 255, 255, 0.8));
		background: var(
			--light-surface,
			linear-gradient(
				139deg,
				rgba(255, 255, 255, 0.8) 0.52%,
				rgba(249, 249, 249, 0.56) 49.32%,
				rgba(239, 239, 239, 0.24) 98.12%
			)
		);
		box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
		backdrop-filter: blur(15px);

		.btns {
			display: flex;
			align-items: center;
			height: fit-content;
			width: 100%;
			justify-content: space-between;

			.operationType {
				.allBtn {
					border-top-right-radius: 0 !important;
					border-bottom-right-radius: 0 !important;
				}

				.depositBtn {
					border-radius: 0 !important;
				}

				.withdrawBtn {
					border-top-left-radius: 0 !important;
					border-bottom-left-radius: 0 !important;
				}
			}

			.filtersDownloadPrint {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 24px;

				.filters {
					display: flex;
					align-items: center;
					gap: 8px;

					.datepickerDropdown,
					.sortAndFilterDropdown {
						.dropdown-menu {
							background-color: white;
						}
					}
				}

				/* .downloadPrint {
					.downloadBtn {
						border-top-right-radius: 0 !important;
						border-bottom-right-radius: 0 !important;
					}

					.printBtn {
						border-top-left-radius: 0 !important;
						border-bottom-left-radius: 0 !important;
					}
				} */
			}
		}
	}
}

.tablet {
	.historyPage {
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: start;
		gap: 24px;
		height: calc(100vh - 81px);
		height: calc(100svh - 81px);
		padding: 24px 24px 0 24px;

		border-radius: 20px;
		border: 1px solid var(--light-gr-stroke, rgba(255, 255, 255, 0.8));
		background: var(
			--light-surface,
			linear-gradient(
				139deg,
				rgba(255, 255, 255, 0.8) 0.52%,
				rgba(249, 249, 249, 0.56) 49.32%,
				rgba(239, 239, 239, 0.24) 98.12%
			)
		);
		box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
		backdrop-filter: blur(15px);

		.btns {
			display: flex;
			align-items: center;
			height: fit-content;
			width: 100%;
			justify-content: space-between;

			.operationType {
				.allBtn {
					border-top-right-radius: 0 !important;
					border-bottom-right-radius: 0 !important;
				}

				.depositBtn {
					border-radius: 0 !important;
				}

				.withdrawBtn {
					border-top-left-radius: 0 !important;
					border-bottom-left-radius: 0 !important;
				}
			}

			.filtersDownloadPrint {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 8px;

				.datepickerDropdown,
				.sortAndFilterDropdown {
					width: 100%;

					& > button {
						width: 100%;
					}

					.dropdown-menu {
						background-color: white;
					}
				}

				.sortPrint {
					display: flex;
					align-items: center;
					justify-content: end;
					width: 100%;
					gap: 14px;
				}

				/* .downloadPrint {
					.downloadBtn {
						border-top-right-radius: 0 !important;
						border-bottom-right-radius: 0 !important;
					}

					.printBtn {
						border-top-left-radius: 0 !important;
						border-bottom-left-radius: 0 !important;
					}
				} */
			}
		}
	}
}

.mobile {
	.historyPage {
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: start;
		gap: 24px;
		height: calc(100vh - 81px);
		height: calc(100svh - 81px);
		padding: 24px 4px 0 4px;

		border-radius: 20px;
		border: 1px solid var(--light-gr-stroke, rgba(255, 255, 255, 0.8));
		background: var(
			--light-surface,
			linear-gradient(
				139deg,
				rgba(255, 255, 255, 0.8) 0.52%,
				rgba(249, 249, 249, 0.56) 49.32%,
				rgba(239, 239, 239, 0.24) 98.12%
			)
		);
		box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
		backdrop-filter: blur(15px);

		.btns {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			gap: 14px;
			padding: 0 8px;

			.operationType {
				width: 100%;
				.allBtn {
					border-top-right-radius: 0 !important;
					border-bottom-right-radius: 0 !important;
				}

				.depositBtn {
					border-radius: 0 !important;
				}

				.withdrawBtn {
					border-top-left-radius: 0 !important;
					border-bottom-left-radius: 0 !important;
				}
			}

			.filtersDownloadPrint {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 8px;
				width: 100%;

				.datepickerDropdown,
				.sortAndFilterDropdown {
					width: 100%;

					& > button {
						width: 100%;
					}

					.dropdown-menu {
						background-color: white;
					}
				}

				.sortPrint {
					display: flex;
					align-items: center;
					justify-content: end;
					width: 100%;
					gap: 14px;
				}

				/* .downloadPrint {
					.downloadBtn {
						border-top-right-radius: 0 !important;
						border-bottom-right-radius: 0 !important;
					}

					.printBtn {
						border-top-left-radius: 0 !important;
						border-bottom-left-radius: 0 !important;
					}
				} */
			}
		}
	}
}

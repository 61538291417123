.desktop,
.tablet,
.mobile {
	.redirectPage {
		background: url('../../assets/authorizationBG.svg') no-repeat;
		background-size: cover;
		background-position: center;

		width: 100%;
		height: 100svh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 24px;
		padding: 24px 12px 36px 12px;
		// padding-bottom: 99px;
		// padding-bottom: 75px;

		img {
			width: 75px;
			height: 75px;
		}

		/* h1 {
			text-align: center;
		} */
	}
}

/* .tablet {
	.redirectPage {
		padding-bottom: 45px;
	}
}

.mobile {
	.redirectPage {
		padding-bottom: 0;
	}
} */

.desktop {
	.dailyStatisticTab {
		display: flex;
		padding: 0px 1px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: /* 33.33% */ calc(50% - 7px);

		border-radius: 20px;
		border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
		background: var(--button-background-w, rgba(255, 255, 255, 0.6));

		.title {
			display: flex;
			padding: 8px 24px;
			justify-content: center;
			align-items: center;
			gap: 10px;

			p {
				color: var(--grey-w, #424242);
				text-align: center;
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
		}

		hr {
			width: 100%;
			height: 1%;
			color: rgba(0, 0, 0, 0.07);
			background: rgba(0, 0, 0, 0.07);
			margin: 0;
			padding: 0;
		}

		.numberStats {
			display: flex;
			padding: 8px 24px;
			justify-content: center;
			align-items: flex-start;
			gap: 4px;

			p {
				text-align: center;
				font-family: Inter;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;

				&.green {
					color: #3bbb26;
				}

				&.red {
					color: #ff5151;
				}
			}

			.percentStat {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				gap: 1px;

				p {
					color: var(--Green, #3bbb26);
					font-family: Inter;
					font-size: 8px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}
			}
		}
	}
}

.tablet {
	.dailyStatisticTab {
		display: flex;
		padding: 0px 1px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: /* 33.33% */ calc(50% - 4px);

		border-radius: 20px;
		border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
		background: var(--button-background-w, rgba(255, 255, 255, 0.6));

		.title {
			display: flex;
			padding: 8px 12px;
			justify-content: center;
			align-items: center;
			gap: 10px;

			p {
				color: var(--grey-w, #424242);
				text-align: center;
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
		}

		hr {
			width: 100%;
			height: 1%;
			color: rgba(0, 0, 0, 0.07);
			background: rgba(0, 0, 0, 0.07);
			margin: 0;
			padding: 0;
		}

		.numberStats {
			display: flex;
			padding: 8px 12px;
			justify-content: center;
			align-items: flex-start;
			gap: 4px;

			p {
				text-align: center;
				font-family: Inter;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;

				&.green {
					color: #3bbb26;
				}

				&.red {
					color: #ff5151;
				}
			}

			.percentStat {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				gap: 1px;

				p {
					color: var(--Green, #3bbb26);
					font-family: Inter;
					font-size: 8px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}
			}
		}
	}
}

.historyTable.desktop {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
	width: 100%;
	// height: calc(100% - 48px);
	overflow-y: hidden;
	gap: 14px;

	p {
		margin: 0; // for react-to-print
	}

	.titles {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		p {
			color: var(--grey-w, #424242);
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;

			&.deviceName {
				width: 115px;
			}

			&.deviceId {
				width: 90px;
				// text-align: end;
			}

			&.player {
				width: 80px;
				// text-align: center;
			}

			&.transactionId {
				width: 140px;
			}

			&.amount {
				width: 100px;
				// text-align: center;
			}

			&.type {
				width: 100px;
				// text-align: center;

				@media print {
					text-align: center; // for react-to-print
				}
			}

			&.date {
				width: 79px;
				// text-align: center;
			}

			/* &.print {
				width: 52px;
				// text-align: center;
			} */
		}
	}

	.historyTabs {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: start;
		overflow-y: auto;
		// scroll-behavior: smooth;
		// scrollbar-width: thin;

		.lineWave {
			align-self: center;

			svg {
				margin-left: 35px;

				rect {
					width: 7px;
					height: 35px;
				}
			}
		}

		&.noPlaces {
			padding: 27px;
			align-items: center;

			p {
				color: #000;
				text-align: center;
				font-family: Inter;
				font-size: 28px;
				font-style: normal;
				font-weight: 800;
				line-height: normal;
				text-wrap: balance;
				white-space: pre-wrap;
			}
		}
	}
}

.historyTable.tablet {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
	width: 100%;
	// height: calc(100% - 48px);
	overflow-y: hidden;
	gap: 14px;

	p {
		margin: 0; // for react-to-print
	}

	.titles {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		p {
			color: var(--grey-w, #424242);
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;

			&.deviceName {
				width: 110px;
			}

			&.deviceId {
				width: 75px;
				// text-align: end;
			}

			&.player {
				width: 50px;
				// text-align: center;
			}

			&.transactionId {
				width: 110px;
			}

			&.amount {
				width: 110px;
				// text-align: center;
			}

			&.type {
				width: 25px;
				// text-align: center;
			}

			&.date {
				width: 95px;
				text-align: center;
			}

			&.print {
				width: 50px;
			}
		}
	}

	.historyTabs {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: start;
		overflow-y: auto;
		// scroll-behavior: smooth;
		// scrollbar-width: thin;

		.lineWave {
			align-self: center;

			svg {
				margin-left: 40px;

				rect {
					width: 7px;
					height: 35px;
				}
			}
		}

		&.noPlaces {
			padding: 27px;
			align-items: center;

			p {
				color: #000;
				text-align: center;
				font-family: Inter;
				font-size: 28px;
				font-style: normal;
				font-weight: 800;
				line-height: normal;
				text-wrap: balance;
				white-space: pre-wrap;
			}
		}
	}
}

.historyTable.mobile {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
	width: 100%;
	// height: calc(100% - 48px);
	overflow-y: hidden;
	gap: 14px;

	p {
		margin: 0; // for react-to-print
	}

	.titles {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		p {
			color: var(--grey-w, #424242);
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;

			&.deviceName {
				width: 115px;

				@media print {
					width: 130px; // for react-to-print
				}
			}

			/* &.deviceId {
        width: 75px;
        // text-align: end;
      } */

			/* &.transactionId {
        width: 110px;
      } */

			&.type {
				width: 50px;
				// text-align: center;
			}

			&.amount {
				width: 70px;
				// text-align: center;
			}

			&.player {
				width: 60px;
				text-align: end;
			}

			&.date {
				width: 110px;
				padding-left: 23px;
				// text-align: center;
			}

			/* &.print {
        width: 50px;
      } */
		}
	}

	.historyTabs {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: start;
		overflow-y: auto;
		// scroll-behavior: smooth;
		// scrollbar-width: thin;

		.lineWave {
			align-self: center;

			svg {
				margin-left: 30px;

				rect {
					width: 7px;
					height: 35px;
				}
			}
		}

		&.noPlaces {
			padding: 27px;
			align-items: center;

			p {
				color: #000;
				text-align: center;
				font-family: Inter;
				font-size: 28px;
				font-style: normal;
				font-weight: 800;
				line-height: normal;
				text-wrap: balance;
				white-space: pre-wrap;
			}
		}
	}
}

.authorizationBG {
	background: url('../../assets/authorizationBG.svg') no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1;
}

.desktop {
	.authorizationPage {
		height: calc(100vh - 60px);
		height: calc(100svh - 60px);

		.loginBlock {
			display: flex;
			width: 1154px;
			height: 100%;
			padding: 24px 42px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 12px;

			border-radius: 20px;
			border: 1px solid var(--light-gr-stroke, rgba(255, 255, 255, 0.8));
			background: var(
				--light-surface,
				linear-gradient(
					139deg,
					rgba(255, 255, 255, 0.8) 0.52%,
					rgba(249, 249, 249, 0.56) 49.32%,
					rgba(239, 239, 239, 0.24) 98.12%
				)
			);
			box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
			backdrop-filter: blur(15px);
		}

		.authorizationForm {
			display: flex;
			flex-direction: column;
			gap: 8px;
			width: 212px;

			.rememberMeCheckbox {
				display: flex;
				gap: 8px;
				align-items: center;
				accent-color: #3262de;
				border-radius: 20px;
				margin-left: 5px;

				.form-check-input {
					width: 18px;
					height: 18px;
					padding: 0px;
					margin: 0;
					border-radius: 4px !important;
					box-shadow: none;

					&:checked {
						background-color: #3262de;
						border-color: #3262de;
						color: #000433;
						background-image: url('../../assets/icons/lineIcon.svg');
						box-shadow: none;
					}
				}

				.form-check-label {
					color: var(--grey-w, #424242);
					text-align: center;
					/* regular big */
					font-family: Inter;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}
			}

			.loginBtn {
				width: fit-content;
				align-self: end;
				/* blue button glue */
				box-shadow: 0px 0px 20px 0px rgba(63, 99, 192, 0.3);
			}
		}

		.helpLinks {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			a {
				color: var(--very-light-text, #9e9e9e);
				text-align: center;
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				text-decoration: none;
			}

			p {
				color: var(--very-light-text, #9e9e9e);
				text-align: center;
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			.techSupport {
				margin-top: 42px;
			}
		}
	}
}

.tablet {
	.authorizationPage {
		height: calc(100vh - 64px);
		height: calc(100svh - 64px);

		.loginBlock {
			display: flex;
			height: 100%;
			padding: 24px 42px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 42px;
			align-self: stretch;

			border-radius: 20px;
			border: 1px solid var(--light-gr-stroke, rgba(255, 255, 255, 0.8));
			background: var(
				--light-surface,
				linear-gradient(
					139deg,
					rgba(255, 255, 255, 0.8) 0.52%,
					rgba(249, 249, 249, 0.56) 49.32%,
					rgba(239, 239, 239, 0.24) 98.12%
				)
			);
			box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
			backdrop-filter: blur(15px);
		}

		.authorizationForm {
			display: flex;
			flex-direction: column;
			gap: 8px;
			width: 212px;

			.rememberMeCheckbox {
				display: flex;
				gap: 8px;
				align-items: center;
				accent-color: #3262de;
				border-radius: 20px;
				margin-left: 5px;

				.form-check-input {
					width: 18px;
					height: 18px;
					padding: 0;
					margin: 0;
					border-radius: 4px !important;
					box-shadow: none;

					&:checked {
						background-color: #3262de;
						border-color: #3262de;
						color: #000433;
						background-image: url('../../assets/icons/lineIcon.svg');
						box-shadow: none;
					}
				}

				.form-check-label {
					color: var(--grey-w, #424242);
					text-align: center;
					/* regular big */
					font-family: Inter;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}
			}

			.loginBtn {
				width: fit-content;
				align-self: end;
				/* blue button glue */
				box-shadow: 0px 0px 20px 0px rgba(63, 99, 192, 0.3);
			}
		}

		.helpLinks {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			a {
				color: var(--very-light-text, #9e9e9e);
				text-align: center;
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				text-decoration: none;
			}

			p {
				color: var(--very-light-text, #9e9e9e);
				text-align: center;
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			.techSupport {
				margin-top: 42px;
			}
		}
	}
}

.mobile {
	.authorizationPage {
		height: 100vh;
		height: 100svh;

		.loginBlock {
			display: flex;
			height: 100%;
			padding: 24px 42px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 42px;
			align-self: stretch;

			border-radius: 20px;
			border: 1px solid var(--light-gr-stroke, rgba(255, 255, 255, 0.8));
			background: var(
				--light-surface,
				linear-gradient(
					139deg,
					rgba(255, 255, 255, 0.8) 0.52%,
					rgba(249, 249, 249, 0.56) 49.32%,
					rgba(239, 239, 239, 0.24) 98.12%
				)
			);
			box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
			backdrop-filter: blur(15px);
		}

		.authorizationForm {
			display: flex;
			flex-direction: column;
			gap: 8px;
			width: 212px;

			.rememberMeCheckbox {
				display: flex;
				gap: 8px;
				align-items: center;
				accent-color: #3262de;
				border-radius: 20px;
				margin-left: 5px;

				.form-check-input {
					width: 18px;
					height: 18px;
					padding: 0;
					margin: 0;
					border-radius: 4px !important;
					box-shadow: none;

					&:checked {
						background-color: #3262de;
						border-color: #3262de;
						color: #000433;
						background-image: url('../../assets/icons/lineIcon.svg');
						box-shadow: none;
					}
				}

				.form-check-label {
					color: var(--grey-w, #424242);
					text-align: center;
					/* regular big */
					font-family: Inter;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}
			}

			.loginBtn {
				width: fit-content;
				align-self: end;
				/* blue button glue */
				box-shadow: 0px 0px 20px 0px rgba(63, 99, 192, 0.3);
			}
		}

		.helpLinks {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			a {
				color: var(--very-light-text, #9e9e9e);
				text-align: center;
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				text-decoration: none;
			}

			p {
				color: var(--very-light-text, #9e9e9e);
				text-align: center;
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			.techSupport {
				margin-top: 42px;
			}
		}
	}
}

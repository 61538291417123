.desktop {
	.deviceBtn {
		display: flex;
		height: 62px;
		padding: 11.5px 18px;
		justify-content: space-between;
		align-items: center;
		flex-shrink: 0;
		align-self: stretch;
		cursor: pointer;

		border-radius: 30px;
		border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
		background: var(--button-background-w, rgba(255, 255, 255, 0.6));

		& > img {
			width: 24px;
			height: 24px;
		}

		&.pilotGameIcon {
			// border: 3px solid;
			// border-color: #17aaff9f;

			p:nth-child(2) {
				// color: #17aaff;
				color: #d69a00;
			}
		}

		&.fruitsGameIcon {
			// border: 3px solid;
			// border-color: #f0cc007e;
			p:nth-child(2) {
				// color: #d3b404;
				color: #c8212a;
			}
		}

		&.frogGameIcon {
			// border: 3px solid;
			// border-color: #f0cc007e;
			p:nth-child(2) {
				// color: #d3b404;
				color: #5eb402;
			}
		}

		p:nth-child(2) {
			color: var(--Black, #000);
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			text-decoration-line: underline;
			text-decoration-style: solid;
			text-decoration-skip-ink: none;
			text-decoration-thickness: auto;
			text-underline-offset: auto;
			text-underline-position: from-font;
			width: 75px;

			img {
				display: none;
				margin-left: 5px;
				width: 12px;
				height: 12px;
			}

			&:hover {
				img {
					transform: translateY(-0.75px) !important;
					display: inline;
					filter: brightness(0) saturate(100%) invert(0%) sepia(5%)
						saturate(7481%) hue-rotate(279deg) brightness(97%) contrast(106%);
				}
			}
		}

		p:nth-child(3) {
			// width: 90px;
			width: 138px;
			color: var(--Black, #000);
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		/* p:nth-child(3) {
			width: 42px;
			color: transparent;
			opacity: 0;

      		color: var(--Black, #000);
      		font-family: Inter;
      		font-size: 12px;
      		font-style: normal;
      		font-weight: 500;
      		line-height: normal;
		} */

		p:nth-child(4) {
			width: 51px;

			color: var(--Black, #000);
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		.editDeviceBtn {
			padding: 13.5px 18px !important;
		}

		.mockBtn {
			width: 52px;
			height: 43px;
		}

		&.active {
			border-radius: 30px;
			border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
			background: var(--Color, #3262de);

			p:nth-child(1),
			p:nth-child(2),
			p:nth-child(3) {
				color: var(--Black, #fff);
			}

			p:nth-child(1) {
				img {
					filter: brightness(0) saturate(100%) invert(100%) sepia(100%)
						saturate(0%) hue-rotate(188deg) brightness(103%) contrast(105%);
				}
			}
		}
	}
}

.tablet {
	.deviceBtn {
		display: flex;
		height: 62px;
		padding: 12px 18px;
		justify-content: space-between;
		align-items: center;
		flex-shrink: 0;
		align-self: stretch;

		border-radius: 30px;
		border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
		background: var(--button-background-w, rgba(255, 255, 255, 0.6));

		& > img {
			width: 24px;
			height: 24px;
		}

		&.pilotGameIcon {
			// border: 3px solid;
			// border-color: #17aaff9f;

			p:nth-child(2) {
				// color: #17aaff;
				color: #d69a00;
			}
		}

		&.fruitsGameIcon {
			// border: 3px solid;
			// border-color: #f0cc007e;
			p:nth-child(2) {
				// color: #d3b404;
				color: #c8212a;
			}
		}

		&.frogGameIcon {
			// border: 3px solid;
			// border-color: #f0cc007e;
			p:nth-child(2) {
				// color: #d3b404;
				color: #5eb402;
			}
		}

		p:nth-child(2) {
			color: var(--Black, #000);
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			text-decoration-line: underline;
			text-decoration-style: solid;
			text-decoration-skip-ink: none;
			text-decoration-thickness: auto;
			text-underline-offset: auto;
			text-underline-position: from-font;
			width: 75px;

			img {
				margin-left: 5px;
				width: 15px;
				height: 15px;
			}
		}

		p:nth-child(3) {
			// width: 90px;
			width: 110px;
			color: var(--Black, #000);
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		/* p:nth-child(3) {
			width: 42px;
			color: transparent;
			opacity: 0;

     		color: var(--Black, #000);
      		font-family: Inter;
      		font-size: 12px;
      		font-style: normal;
      		font-weight: 500;
      		line-height: normal;
		} */

		p:nth-child(4) {
			width: 36px;
			color: var(--Black, #000);
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		.editDeviceBtn {
			width: fit-content !important;
			padding: 0 !important;
			border: none !important;
			background: none !important;
		}

		.mockBtn {
			width: 14px;
			height: 14px;
		}

		&.active {
			border-radius: 30px;
			border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
			background: var(--Color, #3262de);

			p:nth-child(1),
			p:nth-child(2),
			p:nth-child(3) {
				color: var(--Black, #fff);
			}

			p:nth-child(1) {
				img {
					filter: brightness(0) saturate(100%) invert(100%) sepia(100%)
						saturate(0%) hue-rotate(188deg) brightness(103%) contrast(105%);
				}
			}

			.editDeviceBtn {
				filter: brightness(0) saturate(100%) invert(94%) sepia(92%)
					saturate(31%) hue-rotate(43deg) brightness(108%) contrast(108%);
			}
		}
	}
}

.mobile {
	.deviceBtn {
		display: flex;
		height: 62px;
		padding: 12px 18px;
		justify-content: space-between;
		align-items: center;
		flex-shrink: 0;
		align-self: stretch;

		border-radius: 30px;
		border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
		background: var(--button-background-w, rgba(255, 255, 255, 0.6));

		& > img {
			width: 24px;
			height: 24px;
		}

		&.pilotGameIcon {
			// border: 3px solid;
			// border-color: #17aaff9f;

			p:nth-child(2) {
				// color: #17aaff;
				color: #d69a00;
			}
		}

		&.fruitsGameIcon {
			// border: 3px solid;
			// border-color: #f0cc007e;
			p:nth-child(2) {
				// color: #d3b404;
				color: #c8212a;
			}
		}

		&.frogGameIcon {
			// border: 3px solid;
			// border-color: #f0cc007e;
			p:nth-child(2) {
				// color: #d3b404;
				color: #5eb402;
			}
		}

		p:nth-child(2) {
			color: var(--Black, #000);
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			text-decoration-line: underline;
			text-decoration-style: solid;
			text-decoration-skip-ink: none;
			text-decoration-thickness: auto;
			text-underline-offset: auto;
			text-underline-position: from-font;
			width: 75px;

			img {
				margin-left: 5px;
				width: 15px;
				height: 15px;
			}
		}

		p:nth-child(3) {
			width: 90px;
			color: var(--Black, #000);
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		/* p:nth-child(3) {
				width: 42px;
				color: transparent;
				opacity: 0;
	
				color: var(--Black, #000);
				font-family: Inter;
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
		} */

		p:nth-child(4) {
			width: 51px;

			color: var(--Black, #000);
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		.mockBtn {
			width: 52px;
			height: 39px;
		}

		&.active {
			border-radius: 30px;
			border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
			background: var(--Color, #3262de);

			p:nth-child(1),
			p:nth-child(2),
			p:nth-child(3) {
				color: var(--Black, #fff);
			}

			p:nth-child(1) {
				img {
					filter: brightness(0) saturate(100%) invert(100%) sepia(100%)
						saturate(0%) hue-rotate(188deg) brightness(103%) contrast(105%);
				}
			}
		}
	}
}

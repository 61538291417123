.mobileMenuModal {
	width: 100%;
	height: 100%;

	.modal-dialog {
		min-width: 100%;
		height: 100%;
		margin: 0;
		fill: rgba(0, 0, 0, 0.2);
		backdrop-filter: blur(25px);

		.modal-content {
			width: 303px;
			height: 100%;
			justify-self: end;
			border-radius: 20px 0 0 20px;

			.modal-body {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: start;
				padding: 24px;
				position: relative;

				.closeBtn {
					background: none !important;
					box-shadow: none !important;
					border: none !important;
					padding: 0 !important;
					position: absolute;
					top: 28.16px;
					right: 24px;

					img {
						width: 16.686px !important;
						height: 16.675px !important;
					}
				}

				.navigationBtns {
					display: flex;
					flex-direction: column;
					align-items: start;
					justify-content: center;

					.gamePlatformDropdown {
						margin-bottom: 9.5px;
						margin-left: 6.25px;
					}

					.mobileMenuBtn {
						background: none !important;
						box-shadow: none !important;
						border: none !important;
						gap: 14px !important;
						padding: 12px 14px !important;

						color: var(--Black, #000) !important;
						font-size: 16px !important;

						img {
							width: 18px !important;
							height: 18px !important;
							filter: brightness(0) saturate(100%) invert(0%) sepia(6%)
								saturate(7500%) hue-rotate(284deg) brightness(94%)
								contrast(106%);
						}

						&.statisticBtn {
							color: #0f6f13 !important;

							img {
								filter: brightness(0) saturate(100%) invert(29%) sepia(17%)
									saturate(4668%) hue-rotate(88deg) brightness(94%)
									contrast(90%);
							}
						}

						&.active {
							font-size: 24px !important;
							font-weight: 600 !important;
							padding: 8px 14px !important;

							&.devicesBtn {
								background: rgba(125, 144, 250, 0.2) !important;
								color: var(--Color, #3262de) !important;

								img {
									filter: brightness(0) saturate(100%) invert(28%) sepia(48%)
										saturate(3617%) hue-rotate(217deg) brightness(94%)
										contrast(84%);
								}
							}

							&.statisticBtn {
								background: rgba(83, 229, 80, 0.2) !important;
								color: #0f6f13 !important;

								img {
									filter: brightness(0) saturate(100%) invert(29%) sepia(17%)
										saturate(4668%) hue-rotate(88deg) brightness(94%)
										contrast(90%);
								}
							}

							&.historyBtn {
								background: rgba(224, 229, 80, 0.2) !important;
								color: #6f670f !important;

								img {
									filter: brightness(0) saturate(100%) invert(34%) sepia(100%)
										saturate(357%) hue-rotate(17deg) brightness(92%)
										contrast(96%);
								}
							}
						}
					}

					.float {
						margin-top: 12px;
					}
				}

				.profileBtns {
					display: flex;
					flex-direction: column;
					align-items: start;
					justify-content: center;
					gap: 24px;

					p {
						color: var(--Black, #000);
						font-family: Inter;
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						margin: 0;
					}

					.profile {
						display: flex;
						align-items: center;
						justify-content: start;
						gap: 14px;
						text-decoration: none;

						.avatar {
							display: flex;
							width: 29px;
							height: 29px;
							justify-content: center;
							align-items: center;
							border-radius: 50%;
							flex-shrink: 0;
							background: linear-gradient(
									95deg,
									rgba(255, 255, 255, 0.2) 1.93%,
									rgba(255, 255, 255, 0.04) 97.62%
								),
								var(--Color, #3262de);

							color: var(--White, #fff);
							text-align: center;
							font-family: Inter;
							font-size: 14px;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
						}
					}

					.logoutBtn {
						background: none !important;
						box-shadow: none !important;
						border: none !important;
						padding: 0 !important;
						font-size: 16px !important;

						img {
							width: 16px !important;
							height: 16px !important;
						}
					}
				}
			}
		}
	}
}

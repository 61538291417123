.datepickerInDropdown {
	padding: 4px 18px 18px 18px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;

	.inputs {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		.datepickerWrapper {
			label {
				text-align: center !important;
				font-family: Inter !important;
				font-size: 14px !important;
				font-style: normal !important;
				font-weight: 500 !important;
				line-height: normal !important;
				color: var(--grey-w, #606060) !important;
				padding-left: 3px;
			}

			.input-picker--input {
				width: 120px;
				height: 42px;
				padding: 16px 18px;
				border-radius: 20px;
				box-shadow: none !important;
				border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
				background: var(--button-background-w, rgba(255, 255, 255, 0.6));

				&:hover {
					border: 1px solid #969bab;
				}

				&:active,
				&:focus {
					border: 1px solid var(--Color, #3262de);
				}
			}

			.utcDropdown {
				.dropdown-toggle {
					width: 170px;
					height: 42px;
					padding: 16px 18px;
					border-radius: 20px;
				}

				.dropdown-menu.show {
					max-height: 196px !important;
					overflow-y: auto;
					// scrollbar-width: thin;
					width: 100%;
					box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.17);

					.dropdown-item {
						justify-content: center;
					}
				}
			}

			.datetimeBlock {
				display: flex;
				align-items: center;
				gap: 8px;

				.react-time-picker__wrapper {
					width: 120px;
					height: 42px;
					border-radius: 20px;
					box-shadow: none !important;
					border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
					background: var(--button-background-w, rgba(255, 255, 255, 0.6));
					text-align: center;

					&:hover {
						border: 1px solid #969bab;
					}

					&:active,
					&:focus {
						border: 1px solid var(--Color, #3262de);
					}
				}
			}

			.calender-modal {
				top: 42px !important;
				bottom: auto !important;
			}
		}
	}

	.btns {
		width: 100%;
		display: flex;
		flex-direction: row !important;
		align-items: center;
		justify-content: space-between;

		button {
			width: calc(50% - 4px) !important;
		}
	}
}

.mobile {
	.datepickerInDropdown {
		.inputs {
			.datepickerWrapper {
				@media (max-height: 770px) {
					.calender-modal {
						width: 250px;

						.dtWrapper {
							.header {
								padding: 16px;
							}

							.daysList {
								padding: 4px 0;

								.is-pointer {
									width: calc(100% / 7) !important;
									height: calc(100% / 7) !important;
									line-height: calc(310px / 8.5);
								}
							}
						}
					}
				}
			}
		}
	}
}

.changePasswordModal {
	background: rgba(155, 155, 155, 0.1);
	backdrop-filter: blur(25px);

	.modal-dialog {
		align-items: center;
		justify-content: center;

		.modal-content {
			width: fit-content;
			height: fit-content;
			border: none;
			border: 1px solid rgba(0, 0, 0, 0.07);
			border-radius: 20px;

			.modal-body {
				padding: 0;
				display: flex;
				flex-wrap: wrap;
				padding: 24px;
				min-width: 304px;
				min-height: 340px;

				.lineWave {
					width: 100%;
					align-self: center;
					justify-content: center;

					svg {
						margin-left: 47px;
						margin-bottom: 50px;

						rect {
							width: 7px;
							height: 20px;
						}
					}
				}

				.mainForm {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					gap: 14px;

					h2 {
						color: #000;
						font-family: Inter;
						font-size: 24px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
						margin: 0;
					}

					.btns {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;

						button {
							width: calc(50% - 4px) !important;
						}
					}
				}
			}
		}
	}
}

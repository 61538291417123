.editDeviceModal {
	background: rgba(155, 155, 155, 0.1);
	backdrop-filter: blur(25px);

	.modal-dialog {
		align-items: center;
		justify-content: center;
	}

	.modal-content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 14px;
		flex-shrink: 0;
		width: fit-content;

		border-radius: 20px;
		border: 1px solid var(--light-gr-stroke, rgba(255, 255, 255, 0.8));
		background: var(
			--light-surface,
			linear-gradient(
				139deg,
				rgba(255, 255, 255, 0.8) 0.52%,
				rgba(249, 249, 249, 0.56) 49.32%,
				rgba(239, 239, 239, 0.24) 98.12%
			)
		);
		box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
		backdrop-filter: blur(15px);

		.modal-body {
			padding: 24px;

			.lineWave {
				svg {
					margin-left: 40px;
					margin-bottom: 35px;

					rect {
						width: 7px;
						height: 20px;
					}
				}
			}

			.mainForm {
				display: flex;
				flex-direction: column;
				justify-content: start;
				align-items: start;
				gap: 14px;

				h2 {
					color: #000;
					text-align: center;
					font-family: Inter;
					font-size: 24px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					margin: 0;
				}

				.id,
				.uuid,
				.gameId,
				.activeGame,
				.position,
				.name {
					display: flex;
					flex-direction: column;
					align-items: start;
					justify-content: center;
					gap: 4px;
					width: 100%;

					p {
						&:first-child {
							color: #000;
							text-align: center;
							font-family: Inter;
							font-size: 14px;
							font-style: normal;
							font-weight: 600;
							line-height: normal;
							margin: 0;
						}

						&:last-child {
							color: var(--grey-w, #424242);
							font-family: Inter;
							font-size: 16px;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
							margin: 0;
						}
					}
				}

				.uuid {
					p {
						&:last-child {
							text-decoration-line: underline;
							text-decoration-style: solid;
							text-decoration-skip-ink: none;
							text-decoration-thickness: auto;
							text-underline-offset: auto;
							text-underline-position: from-font;
							width: 330px !important;

							img {
								margin-left: 5px;
								width: 15px;
								height: 15px;
							}
						}
					}

					&.hover {
						p {
							&:last-child {
								cursor: pointer;
							}
						}
						img {
							display: none;
							margin-left: 5px;
							width: 12px;
							height: 12px;
						}

						&:hover {
							img {
								transform: translateY(-0.75px) !important;
								display: inline;
								filter: brightness(0) saturate(100%) invert(0%) sepia(5%)
									saturate(7481%) hue-rotate(279deg) brightness(97%)
									contrast(106%);
							}
						}
					}
				}

				/* .activeGame {
					gap: 14px;
					// .activeGameDropdown {
						width: 100%;

						button {
							width: 100%;
							height: 48px;

							img {
								filter: none !important;
								&:first-child {
									width: 32px;
									height: 32px;
								}
							}
						}

						&.rocket {
							button {
								background-color: #17aaff9f;
								color: white !important;
							}
						}

						&.fruit {
							button {
								background-color: #f0cc007e;
								color: var(--grey-w, #424242) !important;
							}
						}

						.dropdown-menu {
							width: 100%;

							.dropdown-item {
								img {
									width: 48px;
									height: 48px;
								}

								&:first-child {
									background-color: #17aaff9f;
									color: white;
								}

								&:last-child {
									background-color: #f0cc007e;
								}
							}
						}
					} //

					.gameList {
						width: fit-content;
						display: flex;
						justify-content: space-evenly;
						align-items: center;
						width: 100%;

						.selectGameBtn {
							border-radius: 20px;
							padding: 6px;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							gap: 4px;

							border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
							background: var(--button-background-w, rgba(255, 255, 255, 0.6));

							color: var(--grey-w, #606060);

							&:hover {
								border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
								background: linear-gradient(
										0deg,
										rgba(0, 0, 0, 0.1) 0%,
										rgba(0, 0, 0, 0.1) 100%
									),
									var(--button-background-w, rgba(255, 255, 255, 0.6));
							}

							&:active {
								border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07)) !important;
								background: linear-gradient(
										90deg,
										rgba(255, 255, 255, 0) 0%,
										rgba(255, 255, 255, 0) 92.25%,
										rgba(255, 255, 255, 0) 100%
									),
									linear-gradient(
										0deg,
										rgba(0, 0, 0, 0.2) 0%,
										rgba(0, 0, 0, 0.2) 100%
									),
									var(--button-background-w, rgba(255, 255, 255, 0.6)) !important;
							}

							&:disabled {
								border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
								background: linear-gradient(
										90deg,
										rgba(255, 255, 255, 0) 0%,
										rgba(255, 255, 255, 0.8) 42.9%,
										rgba(255, 255, 255, 0) 100%
									),
									linear-gradient(
										0deg,
										rgba(0, 0, 0, 0.1) 0%,
										rgba(0, 0, 0, 0.1) 100%
									),
									var(--button-background-w, rgba(255, 255, 255, 0.6));
							}

							&.active {
								border: 3px solid !important;

								img {
									opacity: 1;
								}

								&.rocketBtn {
									border-color: #17aaff9f !important;
								}

								&.fruitBtn {
									border-color: #f0ca00 !important;
								}
							}

							p {
								color: var(--grey-w, #424242);
								// color: var(--Black, #000);
								font-size: 13px;
								font-weight: 500;
								text-wrap: nowrap;
								margin: 0;
							}

							img {
								width: 48px;
								height: 48px;
								opacity: 0.55;
								// border: 3px solid;
								border-radius: 50%;

								&.rocketGameIcon {
									border-color: #3262de;
								}

								&.fruitGameIcon {
									border-color: #f0ca00;
								} //
							}

							&.active {
								border: 1px solid var(--grey-w, #424242);
							}
						}
					}
				} */

				.visibilitySwitch {
					margin: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 10px;

					#visibilitySwitch {
						width: 44px;
						height: 23px;
						position: relative;

						background-color: #ffffff;
						background-image: url('../../../../assets/switchPartBlue.svg');
						background-size: 18px 18px;
						background-position: left center;
						border: 4px solid #ffffff;

						&:checked {
							background-color: var(--Color, #3262de);
							background-image: url('../../../../assets/switchPartWhite.svg');
							background-size: 18px 18px;
							background-position: right center;
							border: 4px solid var(--Color, #3262de);
						}
					}
				}

				.btns {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;

					button {
						width: calc(50% - 4px) !important;
					}
				}
			}

			.approvementBlock {
				display: flex;
				flex-direction: column;
				justify-content: start;
				align-items: start;
				gap: 24px;

				h2 {
					color: #000;
					text-align: center;
					font-family: Inter;
					font-size: 24px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					margin: 0;
				}

				p {
					color: #000;
					text-align: center;
					font-family: Inter;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					margin: 0;

					span {
						font-weight: 600;
					}
				}

				form {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;

					button {
						width: calc(50% - 4px) !important;
					}
				}
			}
		}
	}
}

.input-wrapper {
	position: relative;
	width: 100%;

	input {
		padding: 16px 18px;
		border-radius: 20px;
		box-shadow: none !important;
		border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
		background: var(--button-background-w, rgba(255, 255, 255, 0.6));

		&:hover {
			border: 1px solid #969bab;
		}

		&:active,
		&:focus {
			border: 1px solid var(--Color, #3262de);
		}

		&.is-invalid {
			background-image: none;
			border-color: #ff4e4e;

			&:focus {
				box-shadow: none;
			}
		}
	}

	.invalid-feedback {
		color: #ff4e4e;
		text-align: center;
		/* regular big */
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	.extraInputSign {
		color: var(--grey-w, #424242);
		text-align: center;
		font-family: Inter;
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;

		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
	}

	.inputIcon {
		position: absolute;
		width: 14px;
		height: 14px;
		right: 16px;
		top: 20.5px;
		filter: brightness(0) saturate(100%) invert(37%) sepia(0%) saturate(1489%)
			hue-rotate(183deg) brightness(96%) contrast(86%);
		pointer-events: none;

		&.action {
			cursor: pointer;
			pointer-events: all;
		}
	}
}

.buttonComponent {
	border-radius: 20px !important;
	padding: 11.5px 18px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	gap: 10px !important;

	color: var(--White, #fff) !important;
	text-align: center !important;
	/* general */
	font-family: Inter !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: normal !important;
	width: fit-content !important;

	img {
		width: 14px !important;
		height: 14px !important;
	}

	&.blue {
		border: 1px solid var(--Stroke-gr, rgba(255, 255, 255, 0.8));
		background: linear-gradient(
				95deg,
				rgba(255, 255, 255, 0.2) 1.93%,
				rgba(255, 255, 255, 0.04) 97.62%
			),
			var(--Color, #3262de);
		/* blue button glue */
		box-shadow: 0px 0px 20px 0px rgba(63, 99, 192, 0.3) !important;

		&:hover {
			border: 1px solid var(--surfaces-gr-stroke, rgba(255, 255, 255, 0.8));
			background: linear-gradient(
					90deg,
					rgba(0, 0, 0, 0.05) 0%,
					rgba(0, 0, 0, 0.1) 100%
				),
				linear-gradient(
					95deg,
					rgba(255, 255, 255, 0.2) 1.93%,
					rgba(255, 255, 255, 0.04) 97.62%
				),
				var(--Color, #3262de);
		}

		&:active {
			border: 1px solid var(--surfaces-gr-stroke, rgba(255, 255, 255, 0.8)) !important;
			background: linear-gradient(
					90deg,
					rgba(0, 0, 0, 0.1) 0%,
					rgba(0, 0, 0, 0.2) 100%
				),
				linear-gradient(
					95deg,
					rgba(255, 255, 255, 0.2) 1.93%,
					rgba(255, 255, 255, 0.04) 97.62%
				),
				var(--Color, #3262de) !important;
		}

		&:disabled {
			border: 1px solid var(--surfaces-gr-stroke, rgba(255, 255, 255, 0.8));
			background: linear-gradient(
					0deg,
					rgba(255, 255, 255, 0.3) 0%,
					rgba(255, 255, 255, 0.3) 100%
				),
				linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%),
				linear-gradient(
					95deg,
					rgba(255, 255, 255, 0.2) 1.93%,
					rgba(255, 255, 255, 0.04) 97.62%
				),
				var(--Color, #3262de);
		}
	}

	&.green {
		border: 1px solid var(--surfaces-gr-stroke, rgba(255, 255, 255, 0.8));
		background: linear-gradient(
				95deg,
				rgba(255, 255, 255, 0.2) 1.93%,
				rgba(255, 255, 255, 0) 97.62%
			),
			var(--Green, #3bbb26);
		box-shadow: 0px 0px 10px 0px rgba(49, 159, 31, 0.3) !important;

		&:hover {
			border: 1px solid var(--surfaces-gr-stroke, rgba(255, 255, 255, 0.8));
			background: linear-gradient(
					90deg,
					rgba(0, 0, 0, 0.05) 0%,
					rgba(0, 0, 0, 0.1) 100%
				),
				linear-gradient(
					95deg,
					rgba(255, 255, 255, 0.2) 1.93%,
					rgba(255, 255, 255, 0) 97.62%
				),
				var(--Green, #3bbb26);
		}

		&:active {
			border: 1px solid var(--surfaces-gr-stroke, rgba(255, 255, 255, 0.8)) !important;
			background: linear-gradient(
					90deg,
					rgba(0, 0, 0, 0.1) 0%,
					rgba(0, 0, 0, 0.2) 100%
				),
				linear-gradient(
					95deg,
					rgba(255, 255, 255, 0.2) 1.93%,
					rgba(255, 255, 255, 0) 97.62%
				),
				var(--Green, #3bbb26) !important;
		}

		&:disabled {
			border: 1px solid var(--surfaces-gr-stroke, rgba(255, 255, 255, 0.8));
			background: linear-gradient(
					95deg,
					rgba(255, 255, 255, 0.2) 1.93%,
					rgba(255, 255, 255, 0) 97.62%
				),
				var(--Green, #3bbb26);
		}
	}

	&.white {
		border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
		background: var(--button-background-w, rgba(255, 255, 255, 0.6));

		color: var(--grey-w, #606060) !important;

		&:hover {
			border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
			background: linear-gradient(
					0deg,
					rgba(0, 0, 0, 0.1) 0%,
					rgba(0, 0, 0, 0.1) 100%
				),
				var(--button-background-w, rgba(255, 255, 255, 0.6));
		}

		&:active {
			border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07)) !important;
			background: linear-gradient(
					90deg,
					rgba(255, 255, 255, 0) 0%,
					rgba(255, 255, 255, 0) 92.25%,
					rgba(255, 255, 255, 0) 100%
				),
				linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
				var(--button-background-w, rgba(255, 255, 255, 0.6)) !important;
		}

		&:disabled {
			border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
			background: linear-gradient(
					90deg,
					rgba(255, 255, 255, 0) 0%,
					rgba(255, 255, 255, 0.8) 42.9%,
					rgba(255, 255, 255, 0) 100%
				),
				linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%),
				var(--button-background-w, rgba(255, 255, 255, 0.6));
		}
	}

	&.dark {
		border: 1px solid var(--stroke-b, rgba(255, 255, 255, 0.12));
		background: var(--button-background-b, rgba(0, 0, 0, 0.4));

		color: var(--light-grey-dark-theme, #cecece) !important;

		&:hover {
			border: 1px solid var(--stroke-b, rgba(255, 255, 255, 0.12));
			background: linear-gradient(
					0deg,
					rgba(255, 255, 255, 0.1) 0%,
					rgba(255, 255, 255, 0.1) 100%
				),
				var(--button-background-b, rgba(0, 0, 0, 0.4));
		}

		&:active {
			border: 1px solid var(--stroke-b, rgba(255, 255, 255, 0.12)) !important;
			background: linear-gradient(
					90deg,
					rgba(0, 0, 0, 0) 0%,
					rgba(0, 0, 0, 0) 92.25%,
					rgba(0, 0, 0, 0) 100%
				),
				linear-gradient(
					0deg,
					rgba(255, 255, 255, 0.2) 0%,
					rgba(255, 255, 255, 0.2) 100%
				),
				var(--button-background-b, rgba(0, 0, 0, 0.4)) !important;
		}

		&:disabled {
			border: 1px solid var(--stroke-b, rgba(255, 255, 255, 0.12));
			background: linear-gradient(
					90deg,
					rgba(0, 0, 0, 0) 0%,
					rgba(0, 0, 0, 0.8) 42.9%,
					rgba(0, 0, 0, 0) 100%
				),
				linear-gradient(
					0deg,
					rgba(255, 255, 255, 0.1) 0%,
					rgba(255, 255, 255, 0.1) 100%
				),
				var(--button-background-b, rgba(0, 0, 0, 0.4));
		}

		img {
			filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
				saturate(4249%) hue-rotate(344deg) brightness(114%) contrast(62%);
		}
	}

	&.red {
		border: 1px solid var(--Stroke-gr, rgba(255, 255, 255, 0.8));
		background: var(
			--buttons-red-button,
			linear-gradient(
				95deg,
				rgba(255, 255, 255, 0.2) 1.93%,
				rgba(255, 255, 255, 0) 97.62%
			),
			#eb2f2f
		);

		&:hover {
			border: 1px solid var(--surfaces-gr-stroke, rgba(255, 255, 255, 0.8));
			background: linear-gradient(
					90deg,
					rgba(0, 0, 0, 0.05) 0%,
					rgba(0, 0, 0, 0.1) 100%
				),
				linear-gradient(
					95deg,
					rgba(255, 255, 255, 0.2) 1.93%,
					rgba(255, 255, 255, 0) 97.62%
				),
				#eb2f2f;
		}

		&:active {
			border: 1px solid var(--surfaces-gr-stroke, rgba(255, 255, 255, 0.8)) !important;
			background: linear-gradient(
					90deg,
					rgba(0, 0, 0, 0.1) 0%,
					rgba(0, 0, 0, 0.2) 100%
				),
				linear-gradient(
					95deg,
					rgba(255, 255, 255, 0.2) 1.93%,
					rgba(255, 255, 255, 0) 97.62%
				),
				#eb2f2f !important;
		}

		&:disabled {
			border: 1px solid var(--Stroke-gr, rgba(255, 255, 255, 0.8));
			background: linear-gradient(
					90deg,
					rgba(255, 255, 255, 0) 0%,
					rgba(255, 255, 255, 0.2) 50%,
					rgba(255, 255, 255, 0) 100%
				),
				linear-gradient(
					95deg,
					rgba(255, 255, 255, 0.2) 1.93%,
					rgba(255, 255, 255, 0) 97.62%
				),
				#eb2f2f;
		}
	}
}

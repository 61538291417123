.desktop,
.tablet,
.mobile {
	.getClientPage {
		background: url('../../assets/starsBG.webp');
		background-size: cover;
		background-position: center;
		width: 100svw;
		height: 100svh;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 32px;

		img {
			width: 149px;
			height: 86px;
		}

		h4 {
			font-family: 'Inter', sans-serif;
			font-size: 32px;
			font-weight: 500;
			color: #fff;
			font-style: normal;
			line-height: normal;
			text-transform: uppercase;
			text-align: center;

			span {
				-webkit-text-fill-color: #0000;
				background: linear-gradient(90deg, #ff9d00, #c0f);
				background-clip: text;
				-webkit-background-clip: text;
			}
		}

		.downloadBtn {
			background: linear-gradient(90deg, #9400d9, #990096);
			border: 6px solid #b700ff;
			border-radius: 40px;
			box-shadow: 0 0 20px 5px #ea00ff80;
			padding: 15px 120px;
			transition-duration: 0.3s;

			font-size: 34px;
			font-weight: 600;
			letter-spacing: 1.68px;
			color: #fff;
			font-family: 'Inter', sans-serif;

			&:hover {
				box-shadow: 0 0 60px 10px #ea00ff80;
				opacity: 0.8;
			}

			&:active,
			&:focus {
				outline: none;
			}
		}
	}
}

.mobile {
	.getClientPage {
		.downloadBtn {
			padding: 15px 80px;
		}
	}
}

.modal-backdrop.show {
	background: none;
	opacity: 0;
}

.addDeviceModal {
	background: rgba(155, 155, 155, 0.1);
	backdrop-filter: blur(25px);

	.modal-dialog {
		align-items: center;
		justify-content: center;
	}

	.modal-content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 14px;
		flex-shrink: 0;

		border-radius: 20px;
		border: 1px solid var(--light-gr-stroke, rgba(255, 255, 255, 0.8));
		background: var(
			--light-surface,
			linear-gradient(
				139deg,
				rgba(255, 255, 255, 0.8) 0.52%,
				rgba(249, 249, 249, 0.56) 49.32%,
				rgba(239, 239, 239, 0.24) 98.12%
			)
		);
		box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
		backdrop-filter: blur(15px);
	}

	&.hasNumpad {
		.modal-content {
			width: 364px;
			min-height: 568px;
			padding: 24px 42px;

			.modal-header {
				padding: 0;
				margin: 0;
				border: none;
				display: flex;
				width: 100%;
				align-items: start;
				justify-content: space-between;

				.title {
					display: flex;
					flex-direction: column;
					align-items: start;
					justify-content: center;
					gap: 8px;

					h3 {
						color: #000;
						font-family: Inter;
						font-size: 24px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
						margin: 0;
					}

					p {
						color: var(--grey-w, #424242);
						/* regular big */
						font-family: Inter;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						margin: 0;
					}
				}

				.btn-close {
					--bs-btn-close-bg: url('../../../../assets/icons/closeIcon.svg');
					padding: 0;
					margin: 0;
					background-size: 24px 24px;
					width: 24px;
					height: 24px;
				}
			}

			.modal-body {
				padding: 0;
				margin: 0;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				.numpadWrapper {
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;

					.lineWave {
						svg {
							margin-left: 52px;
							margin-bottom: 65px;

							rect {
								width: 7px;
								height: 20px;
							}
						}
					}

					.gameNumpad {
						display: flex;
						flex-direction: column;
						gap: 12px;

						/* .activeGameDropdown {
							button {
								width: 100%;
								height: 48px;

								img {
									filter: none !important;
									&:first-child {
										width: 32px;
										height: 32px;
									}
								}
							}

							&.rocket {
								button {
									background-color: #17aaff9f;
									color: white !important;
								}
							}

							&.fruit {
								button {
									background-color: #f0cc007e;
									color: var(--grey-w, #424242) !important;
								}
							}

							.dropdown-menu {
								width: 100%;

								.dropdown-item {
									img {
										width: 48px;
										height: 48px;
									}

									&:first-child {
										background-color: #17aaff9f;
										color: white;
									}

									&:last-child {
										background-color: #f0cc007e;
									}
								}
							}
						} */

						/* .gameList {
							width: fit-content;
							display: flex;
							justify-content: space-evenly;
							align-items: center;
							width: 100%;

							.selectGameBtn {
								border-radius: 20px;
								padding: 6px;
								display: flex;
								flex-direction: column;
								align-items: center;
								justify-content: center;
								gap: 4px;

								border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
								background: var(
									--button-background-w,
									rgba(255, 255, 255, 0.6)
								);

								color: var(--grey-w, #606060);

								&:hover {
									border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
									background: linear-gradient(
											0deg,
											rgba(0, 0, 0, 0.1) 0%,
											rgba(0, 0, 0, 0.1) 100%
										),
										var(--button-background-w, rgba(255, 255, 255, 0.6));
								}

								&:active {
									border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07)) !important;
									background: linear-gradient(
											90deg,
											rgba(255, 255, 255, 0) 0%,
											rgba(255, 255, 255, 0) 92.25%,
											rgba(255, 255, 255, 0) 100%
										),
										linear-gradient(
											0deg,
											rgba(0, 0, 0, 0.2) 0%,
											rgba(0, 0, 0, 0.2) 100%
										),
										var(--button-background-w, rgba(255, 255, 255, 0.6)) !important;
								}

								&:disabled {
									border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
									background: linear-gradient(
											90deg,
											rgba(255, 255, 255, 0) 0%,
											rgba(255, 255, 255, 0.8) 42.9%,
											rgba(255, 255, 255, 0) 100%
										),
										linear-gradient(
											0deg,
											rgba(0, 0, 0, 0.1) 0%,
											rgba(0, 0, 0, 0.1) 100%
										),
										var(--button-background-w, rgba(255, 255, 255, 0.6));
								}

								&.active {
									border: 3px solid !important;

									img {
										opacity: 1;
									}

									&.rocketBtn {
										border-color: #17aaff9f !important;
									}

									&.fruitBtn {
										border-color: #f0ca00 !important;
									}
								}

								p {
									color: var(--grey-w, #424242);
									// color: var(--Black, #000);
									font-size: 13px;
									font-weight: 500;
									text-wrap: nowrap;
									margin: 0;
								}

								img {
									width: 48px;
									height: 48px;
									opacity: 0.55;
									// border: 3px solid;
									border-radius: 50%;

									&.rocketGameIcon {
										border-color: #3262de;
									}

									&.fruitGameIcon {
										border-color: #f0ca00;
									} //
								}

								&.active {
									border: 1px solid var(--grey-w, #424242);
								}
							}
						} */

						.numpad {
							.input-wrapper {
								.custom-form-control {
									height: 62px;
									padding: 0 18px;

									color: var(--grey-w, #424242);
									font-family: Inter;
									font-size: 18px;
									font-style: normal;
									font-weight: 500;
									line-height: normal;
								}

								img {
									width: 19.657px;
									height: 16px;
									top: 23px;
									right: 18px;
									filter: brightness(0) saturate(100%) invert(0%) sepia(0%)
										saturate(0%) hue-rotate(324deg) brightness(96%)
										contrast(104%);
								}
							}

							.activateBtn {
								width: 190px !important;
								height: 94px !important;

								font-size: 18px !important;
								font-weight: 600 !important;
							}
						}
					}

					/* .gameList {
						width: fit-content;
						display: flex;
						flex-direction: column;
						justify-content: start;
						align-items: center;

						.selectGameBtn {
							border: none;
							background: none;

							p {
								color: var(--Black, #000);
								font-size: 14px;
								font-weight: 500;
								text-wrap: nowrap;
							}

							img {
								width: 48px;
								height: 48px;
								border: 3px solid;
								border-radius: 50%;

								&.rocketGameIcon {
									border-color: #3262de;
								}

								&.fruitGameIcon {
									border-color: #f0ca00;
								}
							}
						}
					} */
				}
			}
		}
	}

	&.noNumpad {
		.modal-content {
			padding: 42px 24px 14px 24px;
			width: fit-content;

			.modal-body {
				padding: 0;
				display: flex;
				flex-direction: column;
				align-self: center;
				justify-content: center;
				gap: 12px;

				img {
					width: 53px;
					height: 53px;
					align-self: center;
				}

				p {
					margin: 0;
					padding-top: 4px;

					color: var(--grey-w, #424242);
					text-align: center;
					font-family: Inter;
					font-size: 18px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;

					&.error {
						max-width: 256px;
					}
				}

				.okBtn {
					align-self: end;
				}
			}
		}
	}
}

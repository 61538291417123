.float {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	padding: 6px 14px;
	border-radius: 20px;
	border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
	background: var(--button-background-w, rgba(255, 255, 255, 0.6));
	backdrop-filter: blur(10px);

	p {
		color: var(--grey-w, #424242);
		text-align: center;
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		margin: 0;
	}

	h3 {
		color: var(--Black, #000);
		text-align: center;
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin: 0;

		&.green {
			color: #3bbb26;
		}

		&.red {
			color: #ff5151;
		}
	}
}

.statisticModal {
	background: rgba(155, 155, 155, 0.1);
	backdrop-filter: blur(25px);

	.modal-dialog {
		align-items: center;
		justify-content: center;

		.modal-content {
			width: fit-content;
			height: fit-content;
			border: none;
			background: none;

			.modal-body {
				padding: 0;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 12px;

				.datepicker {
					min-height: fit-content;
					width: 589px;
					padding: 10px 24px 24px 24px;
					background-color: white;
					border: 1px solid rgba(0, 0, 0, 0.07);
					border-radius: 20px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					gap: 16px;

					.inputs {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;

						.datepickerWrapper {
							width: calc(33.33% - 5.3px);

							label {
								text-align: center !important;
								font-family: Inter !important;
								font-size: 14px !important;
								font-style: normal !important;
								font-weight: 500 !important;
								line-height: normal !important;
								color: var(--grey-w, #606060) !important;
								padding-left: 3px;
							}

							.input-picker--input {
								height: 42px;
								padding: 16px 18px;
								border-radius: 20px;
								box-shadow: none !important;
								border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
								background: var(
									--button-background-w,
									rgba(255, 255, 255, 0.6)
								);

								&:hover {
									border: 1px solid #969bab;
								}

								&:active,
								&:focus {
									border: 1px solid var(--Color, #3262de);
								}
							}

							.utcDropdown {
								.dropdown-toggle {
									width: 100%;
									height: 42px;
									padding: 16px 18px;
									border-radius: 20px;
								}

								.dropdown-menu.show {
									width: 100%;
									max-height: 196px !important;
									overflow-y: auto;
									// scrollbar-width: thin;
									background-color: white;

									.dropdown-item {
										justify-content: center;
									}
								}
							}

							.datetimeBlock {
								display: flex;
								flex-direction: column;
								align-items: center;
								gap: 8px;

								.react-time-picker {
									width: 100%;

									.react-time-picker__wrapper {
										width: 100%;
										height: 42px;
										border-radius: 20px;
										box-shadow: none !important;
										border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
										background: var(
											--button-background-w,
											rgba(255, 255, 255, 0.6)
										);
										text-align: center;

										&:hover {
											border: 1px solid #969bab;
										}

										&:active,
										&:focus {
											border: 1px solid var(--Color, #3262de);
										}
									}
								}
							}

							.calender-modal {
								top: 42px !important;
								bottom: auto !important;
							}

							.time--input {
								width: 22px;
							}
						}
					}

					.btns {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;

						button {
							width: calc(50% - 4px) !important;
						}
					}
				}

				.statistic {
					width: 589px;
					height: 136px;
					background-color: white;
					border: 1px solid rgba(0, 0, 0, 0.07);
					border-radius: 20px;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;

					.lineWave {
						width: 100%;
						align-self: center;
						justify-content: center;

						svg {
							margin-left: 30px;
							margin-bottom: 70px;

							rect {
								width: 7px;
								height: 20px;
							}
						}
					}

					p {
						margin: 0;
						color: var(--grey-w, #424242);
						text-align: center;
						/* regular big */
						font-family: Inter;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}

					h3 {
						margin: 0;
						color: var(--Color, #3262de);
						text-align: center;
						font-family: Inter;
						font-size: 20px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}

					.delta {
						border-bottom: 1px solid rgba(0, 0, 0, 0.07);
						border-right: 1px solid rgba(0, 0, 0, 0.07);

						h3 {
							&.green {
								color: #3bbb26;
							}

							&.red {
								color: #ff5151;
							}
						}
					}

					.operations {
						border-bottom: 1px solid rgba(0, 0, 0, 0.07);
					}

					.income {
						border-right: 1px solid rgba(0, 0, 0, 0.07);
					}

					.delta,
					.operations,
					.income,
					.outcome {
						padding: 8px 23px;
						width: 289px;
						height: 68px;
					}

					.delta,
					.operations {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						gap: 1px;
					}

					.income,
					.outcome {
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;

						img {
							position: absolute;
							top: 27px;
							left: 35px;
							width: 18px;
							height: 18px;
							box-sizing: content-box;
						}
					}

					.income {
						h3 {
							color: #3bbb26;
						}

						img {
							filter: brightness(0) saturate(100%) invert(58%) sepia(66%)
								saturate(522%) hue-rotate(65deg) brightness(88%) contrast(103%);
						}
					}

					.outcome {
						h3 {
							color: #ff5151;
						}

						img {
							filter: brightness(0) saturate(100%) invert(36%) sepia(100%)
								saturate(1214%) hue-rotate(332deg) brightness(111%)
								contrast(108%);
						}
					}

					.noStatistic {
						font-size: 30px;
						color: var(--Green, #3bbb26);
					}
				}

				&.mobile {
					.datepicker {
						max-width: 291px;
						gap: 18px;

						.inputs {
							flex-direction: column;
							gap: 4px;

							.datepickerWrapper {
								width: 100%;

								.datetimeBlock {
									flex-direction: row;
								}
							}
						}
					}

					.statistic {
						flex-direction: column;
						flex-wrap: nowrap;
						min-width: 291px;
						max-width: 291px;
						min-height: 274px;
						width: fit-content;
						height: fit-content;

						.lineWave {
							svg {
								margin-left: 35px;
								margin-bottom: 35px;
							}
						}

						.delta {
							border: none;
							border-bottom: 1px solid rgba(0, 0, 0, 0.07);
						}

						.operations {
							border: none;
							border-bottom: 1px solid rgba(0, 0, 0, 0.07);
						}

						.income {
							border: none;
							border-bottom: 1px solid rgba(0, 0, 0, 0.07);
						}

						.noStatistic {
							font-size: 24px !important;
						}
					}
				}
			}
		}
	}
}

.navbarComponent {
	.clock {
		display: flex;
		align-items: center;
		gap: 8px;
		color: var(--grey-w, #424242);
		text-align: right;
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;

		.time {
			width: 50px;
			text-align: start;
		}
	}
}

.dropdown {
	scroll-behavior: smooth;

	::-webkit-scrollbar {
		-webkit-appearance: none;
	}

	::-webkit-scrollbar:vertical {
		width: 8px;
	}

	::-webkit-scrollbar:horizontal {
		height: 8px;
	}

	::-webkit-scrollbar-thumb {
		background: var(--Primary-4, #45b26b);
		border-radius: 10px;
		border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
		background: var(--Color, #3263dec4);
	}

	::-webkit-scrollbar-track {
		border-radius: 10px;
		background: transparent;
		margin: 10px 0;
	}

	.dropdown-toggle {
		padding: 8.5px 14px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;

		border-radius: 20px;
		border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
		background: var(--button-background-w, rgba(255, 255, 255, 0.6));

		color: var(--grey-w, #424242) !important;
		text-align: center;
		/* general */
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;

		&:hover {
			border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
			background: linear-gradient(
					0deg,
					rgba(0, 0, 0, 0.1) 0%,
					rgba(0, 0, 0, 0.1) 100%
				),
				var(--button-background-w, rgba(255, 255, 255, 0.6));
		}

		&:active {
			border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
			background: linear-gradient(
					90deg,
					rgba(255, 255, 255, 0) 0%,
					rgba(255, 255, 255, 0) 92.25%,
					rgba(255, 255, 255, 0) 100%
				),
				linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
				var(--button-background-w, rgba(255, 255, 255, 0.6));
		}

		&:disabled {
			border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
			background: linear-gradient(
					90deg,
					rgba(255, 255, 255, 0) 0%,
					rgba(255, 255, 255, 0.8) 42.9%,
					rgba(255, 255, 255, 0) 100%
				),
				linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%),
				var(--button-background-w, rgba(255, 255, 255, 0.6));
		}

		.dropdownIcon {
			height: 16px;
			width: 16px;
		}

		.dropdownArrow {
			height: 10px;
			width: 10px;
			transition: transform 0.5s;
		}

		&::after {
			display: none;
		}

		&.show {
			border-radius: 20px;
			border: 1px solid var(--Stroke-gr, rgba(255, 255, 255, 0.8));
			background: linear-gradient(
					95deg,
					rgba(255, 255, 255, 0.2) 1.93%,
					rgba(255, 255, 255, 0.04) 97.62%
				),
				var(--Color, #3262de);
			color: #ffffff !important;

			.dropdownIcon,
			.dropdownArrow {
				filter: brightness(0) saturate(100%) invert(100%) sepia(100%)
					saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
			}

			.dropdownArrow {
				transform: rotate(-180deg);
			}
		}
	}
}

.dropdown-menu.show {
	margin: 0;
	margin-top: 10.5px;
	padding: 0;
	min-width: fit-content;

	border-radius: 20px;
	border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
	background: var(
		--light-surface,
		linear-gradient(
			139deg,
			rgba(255, 255, 255, 0.8) 0.52%,
			rgba(249, 249, 249, 0.56) 49.32%,
			rgba(239, 239, 239, 0.24) 98.12%
		)
	);
	backdrop-filter: blur(12.5px);

	.dropdown-item {
		display: flex;
		align-items: center;
		justify-content: start;
		gap: 10px;
		padding: 4px 24px 8px 24px;
		border-bottom: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));

		color: var(--grey-w, #424242);
		text-align: center;
		/* general */
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;

		&:first-child {
			padding-top: 18px;
			border-radius: 20px 20px 0 0;
		}

		&:last-child {
			padding-bottom: 18px;
			border: none;
			border-radius: 0 0 20px 20px;
		}

		&:hover,
		&:active {
			background: linear-gradient(
					0deg,
					rgba(50, 98, 222, 0.15) 0%,
					rgba(50, 98, 222, 0.15) 100%
				),
				var(--button-background-w, rgba(255, 255, 255, 0.6));
			backdrop-filter: blur(25px);
			font-weight: 800;
		}
	}
}

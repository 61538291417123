.desktop,
.tablet {
	.navbarComponent {
		&.authorization {
			.timeLanguage {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 24px;
			}
		}
	}
}

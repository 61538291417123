@import '~bootstrap/scss/bootstrap';

.App {
	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
	}

	scroll-behavior: smooth;

	::-webkit-scrollbar {
		-webkit-appearance: none;
	}

	::-webkit-scrollbar:vertical {
		width: 8px;
	}

	::-webkit-scrollbar:horizontal {
		height: 8px;
	}

	::-webkit-scrollbar-thumb {
		background: var(--Primary-4, #45b26b);
		border-radius: 10px;
		border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
		background: var(--Color, #3263dec4);
	}

	::-webkit-scrollbar-track {
		border-radius: 10px;
		background: transparent;
	}

	&.desktop {
		max-width: 100%;
		margin: 0 auto;

		.landingBlock,
		.serviceBlock {
			width: 1440px;
			padding-left: 143px;
			padding-right: 143px;
			margin: 0 auto;
			max-width: 100%;
		}
	}

	&.tablet {
		max-width: 100%;
		margin: 0 auto;

		.landingBlock,
		.serviceBlock {
			width: 1440px;
			padding-left: 14px;
			padding-right: 14px;
			margin: 0 auto;
			max-width: 100%;
		}
	}

	&.mobile {
		overflow-x: hidden;
	}

	.errorText {
		// color: var(--Text-Gray-900, #000433);
		// text-align: left;
		// font-feature-settings: 'liga' off;
		// font-family: Inter;
		// font-size: 48px;
		// font-style: normal;
		// font-weight: 500;
		// line-height: 56px;
		// padding: 12px;
		background: url('../../assets/authorizationBG.svg') no-repeat;
		background-size: cover;
		background-position: center;

		width: 100%;
		height: 100svh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 24px;
		padding: 24px 12px 36px 12px;
	}
}

.desktop,
.tablet,
.mobile {
	.navbarComponent {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		h1 {
			color: var(--Black, #000);
			font-family: 'Red Hat Text';
			font-size: 26px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
	}
}

.desktop {
	.navbarComponent {
		padding: 12px;
	}
}

.tablet {
	.navbarComponent {
		padding: 14px;
	}
}

.mobile {
	.navbarComponent {
		padding: 14px 18px;
	}
}

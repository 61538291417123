.numpad {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;

	.numbers {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		gap: 2px;
		row-gap: 2px;
		width: 286px;
		height: 380px;

		.numBtn {
			width: 94px !important;
			height: 94px !important;
			padding: 0 !important;
			margin: 0 !important;

			color: var(--Black, #000) !important;
			font-size: 32px !important;
			font-weight: 600 !important;
		}
	}
}

.deviceDetailsMobileHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: none;
	padding: 14px 18px;

	.titleAndBackBtn {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;

		.backBtn {
			img {
				padding-right: 4px !important;
				width: 18px !important;
				height: 18px !important;
			}
		}

		h1 {
			margin: 0;
			color: #000;
			font-family: 'Red Hat Text';
			font-size: 24px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
	}

	.menuBtn {
		border: none !important;
		background: none !important;
		box-shadow: none !important;
		padding: 0 !important;
		width: fit-content !important;
		height: fit-content !important;
		border-radius: 0% !important;

		img {
			width: 21px !important;
			height: 16px !important;
		}
	}
}

.deviceDetailsMobile {
	border-radius: 20px;
	border: 1px solid var(--light-gr-stroke, rgba(255, 255, 255, 0.8));
	background: var(
		--Field-white-gr-mobile,
		linear-gradient(
			139deg,
			#fff 0.52%,
			rgba(255, 255, 255, 0.8) 49.32%,
			rgba(239, 239, 239, 0.7) 98.12%
		)
	);

	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: start;
	gap: 24px;
	padding: 24px 18px 0 18px;
	height: calc(100vh - 71px);
	height: calc(100svh - 71px);
	overflow-y: hidden;

	.titleAndToggler {
		display: flex;
		align-items: center;
		justify-content: space-between;

		h1 {
			margin: 0;
			color: #000;
			font-family: 'Red Hat Text';
			font-size: 24px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}

		.btn-group {
			button {
				height: 32px !important;
				padding: 9px 14px !important;

				&:first-child {
					border-radius: 20px 0px 0px 20px !important;
				}

				&:last-child {
					border-radius: 0px 20px 20px 0px !important;
				}
			}
		}
	}

	.detailsTabs.noPlaces {
		display: flex;
		align-items: center;
		justify-content: center;

		.lineWave {
			svg {
				margin-left: 40px;

				rect {
					width: 7px;
					height: 20px;
				}
			}
		}
	}

	.detailsHeader {
		display: flex;
		align-items: center;
		justify-content: /* center */ space-between;
		// gap: 8px;
		width: 100%;

		.dailyStatisticTab {
			display: flex;
			padding: 0px 1px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: /* 33.33% */ calc(50% - 4px);

			border-radius: 20px;
			border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
			background: var(--button-background-w, rgba(255, 255, 255, 0.6));

			.title {
				display: flex;
				padding: 8px 12px;
				justify-content: center;
				align-items: center;
				gap: 10px;

				p {
					color: var(--grey-w, #424242);
					text-align: center;
					font-family: Inter;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					margin: 0;
				}
			}

			hr {
				width: 100%;
				height: 1%;
				color: rgba(0, 0, 0, 0.07);
				background: rgba(0, 0, 0, 0.07);
				margin: 0;
				padding: 0;
			}

			.numberStats {
				display: flex;
				padding: 8px 12px;
				justify-content: center;
				align-items: flex-start;
				gap: 4px;

				p {
					// color: var(--Black, #000);
					text-align: center;
					font-family: Inter;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					margin: 0;

					&.green {
						color: #3bbb26;
					}

					&.red {
						color: #ff5151;
					}
				}

				.percentStat {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					gap: 1px;

					p {
						color: var(--Green, #3bbb26);
						font-family: Inter;
						font-size: 8px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						margin: 0;
					}
				}
			}
		}
	}

	.places {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: start;
		gap: 12px;
		overflow-y: auto;
		// scroll-behavior: smooth;
		// scrollbar-width: thin;

		.noPlaces {
			color: #000;
			text-align: center;
			font-family: Inter;
			font-size: 20px;
			font-style: normal;
			font-weight: 800;
			line-height: normal;
			text-wrap: balance;
			white-space: pre-wrap;
		}

		//DETAILS TAB
		.deviceDetailTab {
			display: flex;
			height: 62px;
			padding: 16px 18px;
			justify-content: space-between;
			align-items: center;
			gap: 2px;
			align-self: stretch;

			border-radius: 30px;
			border: 1px solid var(--stroke-w, rgba(0, 0, 0, 0.07));
			background: rgba(255, 255, 255, 0.7);

			p {
				color: var(--Black, #000);
				font-family: Inter;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				margin: 0;
			}

			p:nth-child(1) {
				width: 75px;
			}

			p:nth-child(3) {
				width: 104px;
				text-align: end;
			}

			.colorWrapper {
				.color {
					width: 24px;
					height: 24px;
					flex-shrink: 0;
					border-radius: 8px;

					&.yellow {
						background: var(--Yellow, #f0ca00);
						box-shadow: 0px 0px 10px 0px rgba(255, 220, 78, 0.3);
					}

					&.blue {
						background: var(--Color, #3262de);
						box-shadow: 0px 0px 10px 0px rgba(50, 98, 222, 0.3);
					}

					&.orange {
						background: var(--Yellow, #f08000);
						box-shadow: 0px 0px 10px 0px rgba(255, 158, 78, 0.3);
					}

					&.purple {
						background: var(--Yellow, #6c03e6);
						box-shadow: 0px 0px 10px 0px rgba(184, 78, 255, 0.3);
					}

					&.green {
						background: var(--Green, #3bbb26);
						box-shadow: 0px 0px 10px 0px rgba(78, 255, 84, 0.3);
					}

					&.red {
						background: var(--Red, #ff4e4e);
						box-shadow: 0px 0px 10px 0px rgba(255, 78, 78, 0.3);
					}

					&.cyan {
						background: var(--Color, #32dec1);
						box-shadow: 0px 0px 10px 0px rgba(50, 211, 222, 0.3);
					}

					&.black {
						background: var(--Color, #000000);
						box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.384);
					}
				}
			}

			.btnWrapper {
				display: flex;
				align-items: center;
				justify-content: end;

				.manageBtn {
					font-size: 0 !important;
					justify-content: center !important;
					gap: 0 !important;
				}
			}
		}

		/* .deviceDetailTab:nth-child(1) {
						.color {
							background: var(--Red, #ff4e4e);
							box-shadow: 0px 0px 10px 0px rgba(255, 78, 78, 0.3);
						}
					}

					.deviceDetailTab:nth-child(2) {
						.color {
							background: var(--Green, #3bbb26);
							box-shadow: 0px 0px 10px 0px rgba(78, 255, 84, 0.3);
						}
					}

					.deviceDetailTab:nth-child(3) {
						.color {
							background: var(--Yellow, #f0ca00);
							box-shadow: 0px 0px 10px 0px rgba(255, 220, 78, 0.3);
						}
					}

					.deviceDetailTab:nth-child(4) {
						.color {
							background: var(--Color, #3262de);
							box-shadow: 0px 0px 10px 0px rgba(50, 98, 222, 0.3);
						}
					} */

		//HISTORY TABS
		.deviceHistoryTab {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 7.5px 0 14.5px 0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.07); // #ebe6e4

			p {
				color: var(--grey-w, #424242);
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				margin: 0;
			}

			.type {
				width: 33px;
				// width: 73px;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 4px;

				img {
					width: 18px;
					height: 18px;
					box-sizing: content-box;
				}

				&.deposit {
					img {
						filter: brightness(0) saturate(100%) invert(58%) sepia(66%)
							saturate(522%) hue-rotate(65deg) brightness(88%) contrast(103%);
					}

					p {
						color: #3bbb26;
					}
				}

				&.withdraw {
					img {
						filter: brightness(0) saturate(100%) invert(36%) sepia(100%)
							saturate(1214%) hue-rotate(332deg) brightness(111%) contrast(108%);
					}

					p {
						color: #ff5151;
					}
				}
			}

			.transactionId {
				width: 80px;
				// width: 62px;

				text-decoration-line: underline;
				text-decoration-style: solid;
				text-decoration-skip-ink: none;
				text-decoration-thickness: auto;
				text-underline-offset: auto;
				text-underline-position: from-font;

				img {
					margin-left: 5px;
					width: 15px;
					height: 15px;
				}
			}

			.player {
				// width: 81px;
				display: flex;
				align-items: center;
				justify-content: start;
				gap: 4px;

				.color {
					width: 24px;
					height: 24px;
					border-radius: 8px;
					display: flex;
					align-items: center;
					justify-content: center;

					color: rgba(255, 255, 255, 0.8);
					font-family: Inter;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;

					&.yellow {
						background: var(--Yellow, #f0ca00);
						box-shadow: 0px 0px 10px 0px rgba(255, 220, 78, 0.3);
					}

					&.blue {
						background: var(--Color, #3262de);
						box-shadow: 0px 0px 10px 0px rgba(50, 98, 222, 0.3);
					}

					&.orange {
						background: var(--Yellow, #f08000);
						box-shadow: 0px 0px 10px 0px rgba(255, 158, 78, 0.3);
					}

					&.purple {
						background: var(--Yellow, #6c03e6);
						box-shadow: 0px 0px 10px 0px rgba(184, 78, 255, 0.3);
					}

					&.green {
						background: var(--Green, #3bbb26);
						box-shadow: 0px 0px 10px 0px rgba(78, 255, 84, 0.3);
					}

					&.red {
						background: var(--Red, #ff4e4e);
						box-shadow: 0px 0px 10px 0px rgba(255, 78, 78, 0.3);
					}

					&.cyan {
						background: var(--Color, #32dec1);
						box-shadow: 0px 0px 10px 0px rgba(50, 211, 222, 0.3);
					}

					&.black {
						background: var(--Color, #000000);
						box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.384);
					}
				}
			}

			.amount {
				// width: 104px;
				width: 70px;

				&.deposit {
					color: #3bbb26;
				}

				&.withdraw {
					color: #ff5151;
				}
			}

			.date {
				// width: 110px;
				// width: 79px;
				width: 73px;
			}
		}
	}
}
